<!-- @format -->

<ng-template #placeholder>
	<span class="field-view" [ngClass]="{ 'field-label': field.subtype === 'linkedfrom' }">
		<ng-container *ngIf="field.subtype === 'linkedfrom' || field.subtype === 'activitylink'; else textPlaceholder">
			{{ 'activity-sidenav.detail.no-activity-selected' | transloco }}
		</ng-container>
	</span>
</ng-template>

<ng-template #textPlaceholder>-</ng-template>

<ng-template #errorField>
	<div class="error-field" *ngIf="error">
		<span>{{ error }}</span>
	</div>
</ng-template>
{{ redraw() }}
<ng-container *ngIf="!initializing">
	<ng-container *ngIf="!error; else errorField">
		<ng-container *ngIf="hasActivityValue; else placeholder">
			<div
                [class]="field.subtype + '-field'"
                class="field-view"
                [class.flex-space-between-align-center]="field.subtype === 'activitylink'"
                [ngSwitch]="field.subtype">
				<ng-container *ngSwitchCase="'activitylink'">
					<span
						(click)="$event.stopPropagation(); !viewContext.overviewPeek ? openActivity(activityValue._id) : null"
						[tippy]="activityValue.name"
						>{{ activityValue.name }}</span>
                        <mat-icon
                            *ngIf="canBeEdited"
                            class="icon-default edit-linked-activity"
                            svgIcon="hailer-linked-activity-edit"
                            data-cy="linked-activity-edit-button"
                            [tippy]="('activity-sidenav.field.edit-activity-link' | transloco )"
                        ></mat-icon>
				</ng-container>
                
				<ng-container *ngSwitchCase="'country'">
					<div class="flexbox-row--center" [tippy]="activityValue.name">
						<span class="flag-icon fi" [ngClass]="flagClass"></span>
						{{ activityValue.name }}
					</div>
				</ng-container>

				<ng-container *ngSwitchCase="'date'">
					<span [tippy]="(activityValue | date : 'mediumDate' : '+0000' : ('misc.date.locale' | transloco)) || ''">
						{{ activityValue | date : 'mediumDate' : '+0000' : ('misc.date.locale' | transloco) }}
					</span>
				</ng-container>

				<ng-container *ngSwitchCase="'daterange'">
					<span
						[tippy]="
							(activityValue.start | date : 'MMMM d, yyyy' : '+0000' : ('misc.date.locale' | transloco)) +
							' - ' +
							(activityValue.end | date : 'MMMM d, yyyy' : '+0000' : ('misc.date.locale' | transloco))
						">
						{{ activityValue.start | date : 'MMMM d, yyyy' : '+0000' : ('misc.date.locale' | transloco) }} -
						{{ activityValue.end | date : 'MMMM d, yyyy' : '+0000' : ('misc.date.locale' | transloco) }}
					</span>
				</ng-container>

				<ng-container *ngSwitchCase="'datetime'">
					<span [tippy]="(activityValue | date : 'MMMM d, HH:mm' : undefined : ('misc.date.locale' | transloco)) || ''">{{
						activityValue | date : 'MMMM d, HH:mm' : undefined : ('misc.date.locale' | transloco)
					}}</span>
				</ng-container>

				<ng-container *ngSwitchCase="'datetimerange'">
					<span
						[tippy]="
							(activityValue.start | date : 'MMMM d, HH:mm' : undefined : ('misc.date.locale' | transloco)) +
							' - ' +
							(activityValue.end | date : 'MMMM d, HH:mm' : undefined : ('misc.date.locale' | transloco))
						">
						{{ activityValue.start | date : 'MMMM d, HH:mm' : undefined : ('misc.date.locale' | transloco) }} -
						{{ activityValue.end | date : 'MMMM d, HH:mm' : undefined : ('misc.date.locale' | transloco) }}
					</span>
				</ng-container>

				<ng-container *ngSwitchCase="'time'">
					<span [tippy]="(activityValue | date : 'HH:mm' : undefined : ('misc.date.locale' | transloco)) || ''">{{
						activityValue | date : 'HH:mm' : undefined : ('misc.date.locale' | transloco)
					}}</span>
				</ng-container>

				<ng-container *ngSwitchCase="'timerange'">
					<span
						[tippy]="
							(activityValue.start | date : 'HH:mm' : undefined : ('misc.date.locale' | transloco)) +
							' - ' +
							(activityValue.end | date : 'HH:mm' : undefined : ('misc.date.locale' | transloco))
						">
						{{ activityValue.start | date : 'HH:mm' : undefined : ('misc.date.locale' | transloco) }} -
						{{ activityValue.end | date : 'HH:mm' : undefined : ('misc.date.locale' | transloco) }}
					</span>
				</ng-container>

				<ng-container *ngSwitchCase="'numeric'">
					<span *ngIf="!field?.modifier?.checkbox" >{{ activityValue | thousandSeparator }}</span>
                    <mat-checkbox *ngIf="field?.modifier?.checkbox" [checked]="!!activityValue" (click)="$event.preventDefault()"></mat-checkbox>
				</ng-container>

				<ng-container *ngSwitchCase="'numericunit'">
					<span>{{ activityValue | thousandSeparator }} {{ field.unit }}</span>
				</ng-container>

				<ng-container *ngSwitchCase="'teams'">
					<span [tippy]="meta || ''">{{ meta }}</span>
				</ng-container>

				<ng-container *ngSwitchCase="'textarea'">
					<span class="activity-text-field" [innerHTML]="activityValue | htmlEscape | hashtag | urlify | nl2br | safe : 'html'"> </span>
				</ng-container>

				<ng-container *ngSwitchCase="'text'">
					<span *ngIf="!field?.modifier?.file"
                        [attr.data-cy]="cypressIdentifier()"
						class="activity-text-field"
						[innerHTML]="activityValue | htmlEscape | hashtag | urlify | nl2br | safe : 'html'">
					</span>
                    <div *ngIf="field?.modifier?.file">
                        <img *ngFor="let fileURL of fileURLs" [src]="fileURL" />
                    </div>
				</ng-container>

				<ng-container *ngSwitchCase="'textpredefinedoptions'">
					<span [innerHTML]="activityValue | htmlEscape | hashtag | urlify | nl2br | safe : 'html'"></span>
				</ng-container>

				<ng-container *ngSwitchCase="'textunit'">
					<span
						[tippy]="activityValue + ' ' + (field.unit || '')"
						[innerHTML]="activityValue + ' ' + (field.unit || '') | htmlEscape | hashtag | urlify | nl2br | safe : 'html'"></span>
				</ng-container>

				<ng-container *ngSwitchCase="'users'">
					<div class="user-field">
						<app-user-profile-picture (click)="openUser(activityValue)" [userId]="activityValue" [size]="userImageSize" class="img-size">
						</app-user-profile-picture>
						<p class="user-name">{{ meta }}</p>
					</div>
				</ng-container>

				<ng-container *ngSwitchCase="'linkedfrom'">
					<app-v3-linked-from-process
						*ngIf="!!(viewContext.activity | async)?._id"
						[linkedFromWorkflowId]="activityValue"
						[description]="field.description || ''"
						[activityLimit]="3"
                        [fieldId]="field.id">
					</app-v3-linked-from-process>
					<div *ngIf="!(viewContext.activity | async)?._id" class="field-label linkedfrom-create-view">
						<span>{{ 'activity-sidenav.linked.create-view' | transloco }}</span>
					</div>
				</ng-container>

				<ng-container *ngSwitchCase="'subheader'">
					<div class="subheader-container">
						<h3 data-cy="activity-sidenav-subheader-title" class="subheader subheader-title" (click)="toggleSubheader()">
							{{ activityValue }}
							<button class="no-button for-icon" [class.rotate-180-deg]="(viewContext.collapsedSubheaderIds | async)?.includes(field.id)">
								<mat-icon svgIcon="hailer-chevron" class="hailer-chevron up"></mat-icon>
							</button>
						</h3>
					</div>
				</ng-container>

				<ng-container *ngSwitchDefault>
					<span [tippy]="activityValue">
						Default ({{ field.subtype }}):
						{{ activityValue }}
					</span>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>
</ng-container>
