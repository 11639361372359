<app-container>
    <div class="main-content">
        <div class="container topbar" id="feed-topbar">

            <!-- Starred posts only -->
            <!-- <button data-cy="only-starred-posts-button" (click)="onlyStarredPosts()" mat-icon-button>
                <mat-icon class="starred-icon" [svgIcon]="onlyStarred ? 'hailer-star-fill' : 'hailer-star'"></mat-icon>
            </button> -->

            <div class="search-filter-wrapper">
                <div class="search-bar-wrapper">
                    <mat-form-field
                        appearance="outline"
                        color="accent"
                        class="form-field search-field highlight-bg-light-theme-only full-width">
                        <mat-icon matPrefix svgIcon="hailer-search" class="dimmed-prefix"></mat-icon>
                        <input matInput type="text" [formControl]="searchForm" [placeholder]="'feed.search' | transloco" />
                    </mat-form-field>
                </div>

                <!-- Feed filter -->
                <button mat-button [matMenuTriggerFor]="filterList" class="feed-filters-button">
                    <mat-icon *ngIf="!areFiltersActive" svgIcon="hailer-filter"></mat-icon>
                    <mat-icon *ngIf="areFiltersActive" svgIcon="hailer-filter-fill"></mat-icon>
                    <span>{{ 'feed.filter_posts' | transloco }}</span>
                    <mat-icon *ngIf="areFiltersActive" svgIcon="hailer-x" (click)="clearFilter($event)"></mat-icon>
                </button>

                <mat-menu #filterList="matMenu" [overlapTrigger]="false" class="checkbox-menu">
                    <button mat-menu-item disabled class="bold">{{ 'feed.filter.type' | transloco }}</button>
                    <button
                        mat-menu-item
                        [role]="'menuitemcheckbox'"
                        *ngFor="let type of typeFilters; trackBy: trackByString"
                        (click)="toggleTypeFilter(type); $event.stopPropagation()">
                        <mat-checkbox [checked]="selectedTypeFilters.has(type)" class="checkbox-thin"></mat-checkbox>
                        {{ 'feed.filter.' + type | transloco }}
                    </button>
                    <button mat-menu-item disabled class="bold">{{ 'feed.filter.workspace' | transloco }}</button>
                    <button mat-menu-item *ngFor="let workspaceId of workspaceIds; trackBy: trackByString" (click)="$event.stopPropagation(); filterNetwork(workspaceId)">
                        <mat-checkbox [checked]="selectedWorkspaces.has(workspaceId)" class="checkbox-thin"></mat-checkbox>
                        {{core.networks.value[workspaceId]?.name}}
                    </button>
                </mat-menu>
            </div>
        </div>
        <div #feedContainer (scroll)="handleFeedScrolling()" class="feed-container" id="feed-content" data-cy="feed-content-container">
            <app-feed-tags *ngIf="feedTags"></app-feed-tags>
            <app-feed-post-editor
                *ngIf="
                    (core.permission.feed.post.create(core.network.value._id) | hasPermission) &&
                    !isPostBeingEdited &&
                    !feed.onlyScheduledPosts
                "
                class="feed__create-post">
            </app-feed-post-editor>
            <ng-container *ngIf="scheduledPosts.length && !feed.onlyScheduledPosts">
                <div class="scheduled-posts-toggle">
                    {{ 'feed.next-scheduled-post' | transloco }}
                    {{ scheduledPosts[0]?.scheduleTime | todayDate | async }}
                    <button class="no-button scheduled-posts-link" [disabled]="feed.editingPost | async" (click)="toggleScheduledPosts()">
                        {{ 'feed.view-scheduled-posts' | transloco }}
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="scheduledPosts.length && feed.onlyScheduledPosts">
                <div class="scheduled-posts-toggle">
                    <app-button
                        [isDisabled]="isPostBeingEdited"
                        [label]="'feed.back-to-feed-view' | transloco"
                        [ariaLabel]="'feed.back-to-feed-view' | transloco"
                        color="secondary"
                        size="small"
                        type="text-with-icon"
                        hailerIconName="hailer-chevron"
                        matIconClass="hailer-chevron left"
                        (clickEvent)="toggleScheduledPosts()"></app-button>
                </div>
            </ng-container>

            <div *ngIf="hiddenPostIds.length && !feed.onlyScheduledPosts" class="hidden-posts-wrapper">
                <button
                    class="no-button panel-header-title flex-row--space-between-centered hidden-posts-btn"
                    (click)="toggleHiddenPosts()">
                    <div class="flex-align-center">
                        <mat-icon svgIcon="hailer-pin" class="margin-right-medium"></mat-icon>
                        <p class="panel-header-title">{{ 'feed.hidden' | transloco }}</p>
                        <div class="amount-bubble">
                            <p>{{ hiddenPostIds.length }}</p>
                        </div>
                    </div>
                    <mat-icon *ngIf="!(loading | async); else loadingSpinner" svgIcon="hailer-chevron" [ngClass]="revealHidden ? 'hailer-chevron up' : 'hailer-chevron'"></mat-icon>
                    <ng-template #loadingSpinner> <mat-spinner [style.margin]="0" [diameter]="20"></mat-spinner> </ng-template>
                </button>
                <ng-container *ngIf="revealHidden">
                    <ng-container *ngFor="let post of hiddenPosts">
                        <app-feed-post
                            *ngIf="core.permission.feed.post.load(post) | hasPermission"
                            data-cy="feed-post-content"
                            [feedPost]="post"
                            class="feed__post hidden-post">
                        </app-feed-post>
                    </ng-container>
                </ng-container>
            </div>

            <ng-container *ngFor="let post of (feed.posts | async)">
                <app-feed-post *ngIf="(core.permission.feed.post.load(post) | hasPermission) && !postIsHidden(post._id)" data-cy="feed-post-content" [feedPost]="post"
                    class="feed__post">
                </app-feed-post>
            </ng-container>
            <div *ngIf="loading | async" class="loading-feed-posts-text mat-elevation-z1">
                <mat-spinner color="accent" [diameter]="24"></mat-spinner>
                <span>{{ 'feed.loading' | transloco}}</span>
            </div>
            <ng-container *ngIf="!(feed.posts | async)?.length && !(loading | async) && searchForm.value !== ''">
                <div class="flex-column--centered">
                    <div class="feed-empty-state"></div>
                    <h3 class="feed-empty-state-text">{{ 'feed.no-search-results' | transloco}}<span class="search-value">{{" "}}"{{searchForm.value}}"</span></h3>
                    <h3 class="feed-empty-state-text">{{ 'feed.try-search-again' | transloco}}</h3>
                </div>
            </ng-container>
        </div>
    </div>
</app-container>