<!-- @format -->

{{ redraw() }}
<ng-container *ngIf="field">
    <ng-container [ngSwitch]="field.subtype">
        <div class="input-fields field-view fill-field-container">
            <ng-container *ngSwitchCase="'activitylink'">
                <ng-container *ngIf="!isNetworkGuest">
                    <div class="grid-container-activity-link">
                        <app-create-linked-activity *ngIf="viewContext.workflow | async" [process]="viewContext.workflow.value!"
                            [fieldId]="field.id" (created)="setLinkedActivity($event, 'created')" [origin]="'v3InputField'">
                        </app-create-linked-activity>

                        <app-activity-selector [showDropDown]="true" class="activity-selector" *ngIf="activitySelectorOptions" [viewContext]="viewContext"
                            #activitySelector [options]="activitySelectorOptions" [focused]="isFieldFocused" [fieldId]="field.id"
                            (clearActivity)="clearValue()" (results)="setLinkedActivity($event, 'selected')">
                        </app-activity-selector>
                    </div>
                </ng-container>

                <ng-container *ngIf="isNetworkGuest">
                    <div class="flex">
                        <app-v3-activity-field [field]="field" class="activity-field" style="flex: 1"> </app-v3-activity-field>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'country'" class="country">
                <app-country-selector *ngIf="countrySelectorOptions" [options]="countrySelectorOptions" [focused]="isFieldFocused"
                    (clear)="clearValue()" (results)="selectCountry($event)">
                </app-country-selector>
            </ng-container>

            <ng-container *ngSwitchCase="'numeric'">
                <div class="grid-container-input-with-clear">
                    <mat-form-field *ngIf="!field.modifier?.checkbox" appearance="fill" class="field-with-clear">
                        <input #focusInput type="number" matInput [placeholder]="placeholder" [formControl]="formControl"
                            (wheel)="$event.preventDefault()" [attr.data-cy]="cypressIdentifier()" />
                    </mat-form-field>
                    <mat-checkbox *ngIf="field.modifier?.checkbox" (change)="convertBooleanToNumber($event)" [checked]="!!+formControl.value"></mat-checkbox>

                    <app-clear-x-button (clickEvent)="clearValue()" [showReloadIcon]="field.functionEnabled"></app-clear-x-button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'numericunit'">
                <div class="grid-container-input-with-clear">
                    <mat-form-field appearance="fill" class="field-with-clear">
                        <input #focusInput type="number" matInput [placeholder]="placeholder" [formControl]="formControl"
                            (wheel)="$event.preventDefault()" [attr.data-cy]="cypressIdentifier()" />
                        <span matSuffix>{{ field?.unit }}</span>
                    </mat-form-field>

                    <app-clear-x-button (clickEvent)="clearValue()" [showReloadIcon]="field.functionEnabled"></app-clear-x-button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'textarea'">
                <div class="grid-container-input-with-clear text-area">
                    <div class="field-with-clear">
                        <mat-form-field appearance="fill">
                            <textarea #focusInput matInput maxlength="32768" [placeholder]="placeholder" [formControl]="formControl"
                                [attr.data-cy]="cypressIdentifier()"></textarea>
                        </mat-form-field>
                        <mat-hint align="start" *ngIf="focusInput.value.length > 20000" class="input-hints">
                            <span>{{ 'activities.fields.max-length-hint' | transloco }}</span>
                            <span>{{ focusInput.value.length || 0 }} / 32768</span>
                        </mat-hint>
                    </div>

                    <app-clear-x-button (clickEvent)="clearValue()" [showReloadIcon]="field.functionEnabled"></app-clear-x-button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'text'">
                <div *ngIf="!field.modifier?.file" class="grid-container-input-with-clear">
                    <div class="field-with-clear">
                        <mat-form-field appearance="fill">
                            <input #focusInput matInput maxlength="2048" [placeholder]="placeholder" [formControl]="formControl"
                                [attr.data-cy]="cypressIdentifier()" [matAutocomplete]="activityNameSearch" />
                            <mat-autocomplete #activityNameSearch="matAutocomplete">
                                <mat-option
                                    *ngFor="let searchedActivity of searchedActivities"
                                    [value]="formControl.value"
                                    (click)="openActivity(searchedActivity._id)">
                                    <div class="flex-row--centered">
                                        <div class="flex-column autocomplete-text">
                                            <p>
                                                {{ searchedActivity.name }}
                                            </p>
                                            <p>
                                                {{getPhaseName(searchedActivity.process, searchedActivity.currentPhase)}}
                                            </p>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-hint align="start" *ngIf="focusInput.value.length > 1000" class="input-hints">
                            <span>{{ 'activities.fields.max-length-hint' | transloco }}</span>
                            <span>{{ focusInput.value.length || 0 }} / 2048</span>
                        </mat-hint>
                        <mat-error *ngIf="(viewContext.errorsInSidenav | async)?.duplicate && field.id === 'nameField'">
                            <div data-cy="duplicate-names-error">{{ 'activity-sidenav.detail.duplicates_error' | transloco }}</div>
                        </mat-error>
                    </div>

                    <app-clear-x-button (clickEvent)="clearValue()" [showReloadIcon]="field.functionEnabled"></app-clear-x-button>
                </div>
                <div *ngIf="field.modifier?.file">
                    <div class="flex" *ngFor="let fileId of fileIds">
                        <div>
                            <img *ngIf="this.viewContext.modifierFiles.value[fileId]" [src]="this.fileService.getFileThumbnail(this.viewContext.modifierFiles.value[fileId])" />
                        </div>
                        <app-button
                            *ngIf="!uploader.showDeleteOnEdit"
                            type="icon"
                            color="icon-destructive"
                            size="small"
                            [ariaLabel]="'activity-sidenav.files.remove-file' | transloco"
                            [hailerIconName]="'hailer-trash'"
                            (click)="setFileToBeRemoved(fileId)"
                            [tippyText]="'activity-sidenav.files.remove-file' | transloco"></app-button>
                    </div>
                    <app-file-uploader #uploader [types]="['image', 'document', 'pdf', 'spreadsheet', 'presentation']"></app-file-uploader>
                    <app-button (clickEvent)="uploader.open()"  label="Upload Image"></app-button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'textpredefinedoptions'">
                <div class="grid-container-input-with-clear">
                    <mat-form-field appearance="fill" class="field-with-clear">
                        <mat-select #optionSelect [placeholder]="placeholder" [formControl]="formControl">
                            <mat-option *ngFor="let option of field.data" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <app-clear-x-button (clickEvent)="clearValue()" [showReloadIcon]="field.functionEnabled"></app-clear-x-button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'textunit'">
                <div class="grid-container-input-with-clear">
                    <div class="field-with-clear">
                        <mat-form-field appearance="fill">
                            <input #focusInput matInput [placeholder]="placeholder" [formControl]="formControl" />
                            <span matSuffix>{{ field?.unit }}</span>
                        </mat-form-field>
                    </div>

                    <app-clear-x-button (clickEvent)="clearValue()" [showReloadIcon]="field.functionEnabled"></app-clear-x-button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'date'">
                <ng-container *ngTemplateOutlet="dateLayout; context: { pickerType: 'calendar' }"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'daterange'">
                <ng-container *ngTemplateOutlet="dateLayout; context: { mode: 'range', pickerType: 'calendar' }"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'datetimerange'">
                <ng-container *ngTemplateOutlet="dateLayout; context: { mode: 'range', pickerType: 'both' }"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'datetime'">
                <ng-container *ngTemplateOutlet="dateLayout; context: { pickerType: 'both' }"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'time'">
                <ng-container *ngTemplateOutlet="dateLayout; context: { pickerType: 'timer' }"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'timerange'">
                <ng-container *ngTemplateOutlet="dateLayout; context: { mode: 'range', pickerType: 'timer' }"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'users'">
                <ng-container *ngIf="!isNetworkGuest && (userSelectorOptions | async)">
                    <div class="grid-user-teams-dropdown">
                        <app-team-user-selector #teamUserSelector *ngIf="userSelectorPredefinedItems | async"
                            [predefinedItems]="userSelectorPredefinedItems" [options]="userSelectorOptions" (results)="selectUser($event)">
                        </app-team-user-selector>
                    </div>
                </ng-container>
                <ng-container *ngIf="isNetworkGuest">
                    <app-v3-activity-field [field]="field"></app-v3-activity-field>
                </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'teams'">
                <ng-container *ngIf="!isNetworkGuest && (teamSelectorOptions | async)">
                    <div class="grid-user-teams-dropdown">
                        <app-team-user-selector #teamUserSelector *ngIf="teamSelectorPredefinedItems | async"
                            [predefinedItems]="teamSelectorPredefinedItems" [options]="teamSelectorOptions" (results)="selectTeam($event)">
                        </app-team-user-selector>
                    </div>
                </ng-container>
                <ng-container *ngIf="isNetworkGuest">
                    <app-v3-activity-field [field]="field"></app-v3-activity-field>
                </ng-container>
            </ng-container>

            <ng-container *ngSwitchDefault> Default ({{ field.subtype }}): {{ activityValue }} </ng-container>
        </div>
    </ng-container>

    <ng-template #dateLayout let-mode="mode" let-pickerType="pickerType">
        <div class="grid-container-input-with-clear">
            <mat-form-field appearance="fill" class="field-with-clear">
                <input #focusInput matInput (focus)="datePicker.open()" [selectMode]="mode || 'single'" [owlDateTime]="datePicker"
                    [placeholder]="placeholder" [formControl]="formControl" autocomplete="off" [attr.data-cy]="cypressIdentifier()" />

                <mat-datepicker-toggle matPrefix [owlDateTimeTrigger]="datePicker"> </mat-datepicker-toggle>

                <owl-date-time [firstDayOfWeek]="1" #datePicker [pickerMode]="datePickerMode" [pickerType]="pickerType"> </owl-date-time>
            </mat-form-field>

            <app-clear-x-button data-cy="activity-sidenav-field-clear-button" (clickEvent)="clearValue()"
                [showReloadIcon]="field.functionEnabled"></app-clear-x-button>
        </div>
    </ng-template>
</ng-container>