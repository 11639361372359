<!-- @format -->

<ng-container *ngIf="quickAddAvailable">
    <!-- @format -->
    <app-button
        *ngIf="!quickAdd"
        [isDisabled]="!!fieldsWithErrors.size"
        [type]="'icon'"
        [size]="'small'"
        [color]="'icon-accent'"
        [hailerIconName]="'hailer-add'"
        [isLoading]="initializing"
        (clickEvent)="openQuickAdd()"></app-button>

    <div *ngIf="!initializing && quickAdd">
        <div class="flex-row--centered quick-add-field-container">
            <ng-container *ngFor="let quickAddField of viewContext?.template?.value?.fields">
                <div class="quick-add-field" *ngIf="field?.modifier?.quickAdd?.fieldIds?.includes(quickAddField.id) && !isStaticField(quickAddField.subtype!)">
                    <p class="quick-add-field-label">{{ quickAddField?.label }}</p>
                    <app-v3-activity-input-field
                        (valueChange)="valueChange(quickAddField.id, $event)"
                        (errors)="errorChange(quickAddField.id, $event)"
                        [field]="viewContext.getTemplateField(quickAddField.id)!">
                    </app-v3-activity-input-field>
                </div>
            </ng-container>
        </div>
        <app-button [isDisabled]="!!fieldsWithErrors.size" [type]="'save'" [size]="'small'" (clickEvent)="save()"></app-button>
    </div>
</ng-container>
