/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { CoreService } from 'app/_services/core.service';
import { RPCService } from 'app/_services/rpc.service';
import { FeedPost, FeedPostEdit } from '../_models';
import { FeedPostComment } from '../_models/feedPostComment.model';
import { FeedLoadPostsDetails, FeedLoadPostsOptions } from '../../../test/deps/hailer-api/shared/feed-types';

@Injectable()
export class FeedService {
    posts = new BehaviorSubject<FeedPost[]>([]);
    details = new BehaviorSubject<FeedLoadPostsDetails>({});
    postUpdated = new Subject<string>();
    focusCreatePost = new Subject<boolean>();

    onlyScheduledPosts = false;
    editingPost = new BehaviorSubject<string | undefined>(undefined);

    reload = new Subject<{ postIds?: string[]; clearPosts?: boolean } | undefined>();

    /**
     * Indicates if all feed posts are loaded.
     *
     * Disables loading more posts if true.
     */
    lastPostLoaded = false;

    constructor(private rpc: RPCService, private core: CoreService) {
        this.core.onAuthenticated(() => {
            this.rpc.signals.subscribe({
                next: (signal) => {
                    switch (signal.sig) {
                        case 'wall2.delete_comment':
                            this.onDeletedComment(signal.meta);
                            break;
                        case 'wall2.delete_post':
                            this.onDeletedPost(signal.meta);
                            break;
                        case 'wall.edited_comment':
                            void this.onEditedComment(signal.meta);
                            break;
                        case 'wall.edited_post':
                        case 'feed.post.edited':
                            void this.onEditedPost(signal.meta);
                            break;
                        case 'wall.like':
                            this.onLikedPost(signal.meta);
                            break;
                        case 'wall2.new_comment':
                            void this.onNewComment(signal.meta);
                            break;
                        case 'wall2.new_post':
                        case 'feed.post.created':
                            this.onNewPost(signal.meta);
                            break;
                        default:
                            break;
                    }
                },
            });
        });
    }

    async editPost(postId: string, editedPost: FeedPostEdit): Promise<void> {
        const updatedFeedPostMap = await this.rpc.requestAsync('v3.feed.post.edit', [{ [postId]: editedPost }])
        const updatedFeedPost = updatedFeedPostMap[postId];
        this.handleUpdatedPost([updatedFeedPost]);
    }

    async loadPosts(
        loadPostsOptions?: FeedLoadPostsOptions,
        options?: { bypassDetailsUpdate?: boolean; clearPosts?: boolean }
    ): Promise<FeedPost[] | undefined> {
        try {
            // Load one more post than needed to check if there are more posts
            const limit = (loadPostsOptions?.limit || 20) + 1;
            const { posts, details } = (await this.rpc.requestAsync('v3.feed.post.load', [{ ...loadPostsOptions, limit }])) as {
                posts: FeedPost[];
                details: FeedLoadPostsDetails;
            };

            if (posts.length === limit) {
                // Remove last post if there are more posts
                this.lastPostLoaded = false;
                posts.pop();
            } else {
                // All posts are loaded
                this.lastPostLoaded = true;
            }

            if (options?.clearPosts) {
                this.posts.next(posts);

                if (!options.bypassDetailsUpdate) {
                    this.details.next(details);
                }

                return posts;
            }

            this.updatePostItems(posts);

            if (!options?.bypassDetailsUpdate) {
                this.details.next(details);
            }

            return posts;
        } catch (error) {
            console.error('Failed to load feed posts', error);
        }

        return undefined;
    }

    updatePostItems(posts: FeedPost[]): void {
        const currentPosts = this.posts.value;
        const details = this.details.value;
        let detailsChanged = false;
        for (const post of posts) {
            const postIndex = currentPosts.findIndex(({ _id }) => _id === post._id);
            if (postIndex < 0) {
                if (this.onlyScheduledPosts && !post.scheduled) {
                    // Don't add post if its not scheduled and only scheduled posts are shown
                    continue;
                }

                if (!this.onlyScheduledPosts && post.scheduled) {
                    // Don't add post if it's scheduled and no scheduled posts are shown
                    continue;
                }

                currentPosts.push(post);
                continue;
            }

            if (post.scheduled) {
                details.scheduledPosts = details.scheduledPosts || [];
                const detailIndex = details.scheduledPosts.findIndex(({ _id }) => _id === post._id);

                if (detailIndex > -1) {
                    details.scheduledPosts[detailIndex] = { _id: post._id, scheduleTime: post.created };
                } else {
                    details.scheduledPosts.push({ _id: post._id, scheduleTime: post.created });
                }

                detailsChanged = true;
            }

            const currentPost = currentPosts[postIndex];
            if (this.onlyScheduledPosts && !!currentPost?.scheduled && !post.scheduled) {
                // Remove released scheduled post
                currentPosts.splice(postIndex, 1);
                continue;
            }

            currentPosts[postIndex] = post;
        }

        if (detailsChanged) {
            details.scheduledPosts?.sort((a, b) => (a.scheduleTime < b.scheduleTime ? -1 : 1));
            this.details.next(details);
        }

        currentPosts.sort((a, b) => {
            if (this.onlyScheduledPosts) {
                if (a.pinned === b.pinned) {
                    return a.created < b.created ? -1 : 1;
                }

                return a.pinned ? -1 : 1;
            }

            if (a.pinned === b.pinned) {
                return a.created > b.created ? -1 : 1;
            }

            return a.pinned ? -1 : 1;
        });

        this.posts.next(currentPosts || []);

        // eslint-disable-next-line @typescript-eslint/naming-convention
        for (const { _id } of posts) {
            this.postUpdated.next(_id);
        }
    }

    /** Searches from currently loaded posts */
    findFeedPost(postId: string): FeedPost | undefined {
        return this.posts.value.find(({ _id }) => _id === postId);
    }

    private removeFeedPost(id: string) {
        const index = this.posts.value.findIndex(feedPost => feedPost._id === id);
        if (index > -1) {
            const posts = this.posts.value;
            posts.splice(index, 1);
            this.posts.next(posts || []);
        }
    }

    private async onNewComment(meta: { post_id?: string; comment_id?: string }): Promise<void> {
        if (!meta.post_id || !meta.comment_id) {
            return;
        }

        const comment = await this.loadComments(meta.post_id, meta.comment_id);
        const feedPost = this.findFeedPost(comment.post_id);
        if (!feedPost) {
            return;
        }

        feedPost.comments = feedPost.comments || [];
        feedPost.comments.push(comment);
        this.postUpdated.next(meta.post_id);
    }

    private async onEditedComment(meta: { post_id?: string; comment_id?: string }): Promise<void> {
        if (!meta.post_id || !meta.comment_id) {
            return;
        }

        const updatedComment = await this.loadComments(meta.post_id, meta.comment_id);
        const feedPost = this.findFeedPost(meta.post_id);

        const comment = feedPost?.comments?.find(postComment => postComment._id === meta.comment_id);
        if (!comment || !updatedComment?.comment) {
            return;
        }

        comment.comment = updatedComment.comment;
        this.postUpdated.next(meta.post_id);
    }

    private onDeletedComment(meta: { post_id?: string; comment_id?: string }): void {
        if (!meta.post_id || !meta.comment_id) {
            return;
        }

        const feedPost = this.findFeedPost(meta.post_id);
        if (!feedPost?.comments) {
            return;
        }

        const index = feedPost.comments.map(comment => comment._id).indexOf(meta.comment_id);
        feedPost.comments.splice(index, 1);
        this.postUpdated.next(meta.post_id);
    }

    private onNewPost(meta: { post_id?: string; postId?: string, postIds?: string[] }): void {
        const updatedPostId: string | undefined = meta?.post_id || meta?.postId;
        let postIds = updatedPostId ? [updatedPostId] : meta.postIds;
        if (!postIds?.length) {
            return;
        }

        if (this.editingPost) {
            postIds = postIds.filter(postId => postId !== this.editingPost.value);
        }

        this.reload.next({ postIds });
    }

    private async onEditedPost(meta: { post_id?: string; postIds?: string[] }): Promise<void> {
        if (!meta.post_id || !meta?.postIds?.length) {
            return;
        }

        const { posts } = await this.rpc.requestAsync('v3.feed.post.load', [{ postIds: meta.postIds || [meta.post_id] }]);
        if (!posts?.length) {
            return;
        }

        this.handleUpdatedPost(posts);
        this.postUpdated.next(meta.post_id);
    }

    private onLikedPost(meta: { post_id?: string; uid?: string; action?: 'like' | 'unlike' }): void {
        if (!meta.post_id || !meta.uid || !meta.action) {
            return;
        }

        const feedPost = this.findFeedPost(meta.post_id);
        if (!feedPost?.likes) {
            return;
        }

        switch (meta.action) {
            case 'like':
                if (feedPost.likes.indexOf(meta.uid) === -1) {
                    feedPost.likes.unshift(meta.uid);
                }
                break;
            case 'unlike':
                if (feedPost.likes.indexOf(meta.uid) > -1) {
                    feedPost.likes.splice(feedPost.likes.indexOf(meta.uid), 1);
                }
                break;
        }
        this.postUpdated.next(meta.post_id);
        // We want to send a update signal
        this.posts.next(this.posts.value || []);
    }

    onDeletedPost(meta: { post_id?: string }): void {
        if (!meta.post_id) {
            return;
        }

        this.removeFeedPost(meta.post_id);
        this.postUpdated.next(meta.post_id);
    }

    private handleUpdatedPost(editedPosts: FeedPost[]): void {
        const posts = this.posts.value;
        for (const post of editedPosts) {
            const postIndex = posts.findIndex(({ _id }) => post._id === _id);
            posts[postIndex] = post;
        }
        this.posts.next(posts || []);
    }

    private async loadComments(postId: string, commentId: string): Promise<FeedPostComment> {
        return this.rpc.requestAsync('wall2.load_comments', [postId, commentId]);
    }
}
