/** @format */

// This is examples how the login URL looks like
// To run this locally, you need an Microsoft Entra Enterprise application in Microsoft Azure
// https://localhost:9443/#/entralogin/clientId/tenantId
// clientId = Enterprise application ID
// tenantId = Tenant ID in Microsoft Entra ID
// https://localhost:9443/#/entralogin/bdb3cb25-db3a-4da0-bde9-169c65ff30bb/78fa02b8-023a-48ea-ad04-66965b1e30bd
// https://testapp.hailer.biz/#/entralogin/bdb3cb25-db3a-4da0-bde9-169c65ff30bb/78fa02b8-023a-48ea-ad04-66965b1e30bd
// https://app.hailer.com/#/entralogin/bdb3cb25-db3a-4da0-bde9-169c65ff30bb/78fa02b8-023a-48ea-ad04-66965b1e30bd

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'app/_services/auth.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Capacitor, Plugins } from '@capacitor/core';
import { CoreService, SessionState } from 'app/_services/core.service';
import { RoutingService } from 'app/_services/routing.service';

const { MsAuthPlugin } = Plugins;

@Component({
    selector: 'app-entra-login',
    templateUrl: './entra-login-page.component.html',
    styleUrls: ['./entra-login-page.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'public',
                alias: 'public',
            },
        },
    ],
})
export class EntraLoginPageComponent implements OnInit, OnDestroy {
    state: Observable<SessionState>;
    isMobile: boolean = Capacitor.isNativePlatform();
    clientId: string | null;
    tenantId: string | null;
    saving = new BehaviorSubject<boolean>(false);
    entraLoginError = '';
    logInInProgress = false;
    redirectToDefaultView = false;

    private onDestroy = new Subject<void>();
    private statusSubscription: Subscription;

    constructor(
        private auth: AuthService,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private core: CoreService,
        private route: RoutingService,
    ) {}

    ngOnInit(): void {
        // Redirect user to default view if logged in
        this.state = this.core.state;
        this.state.pipe(takeUntil(this.onDestroy)).subscribe({
            next: newState => {
                console.log('newState: ', newState);
                if (newState === 'authenticated' && this.core.user.value) {
                    void this.route.toDefaultView();
                }
            },
        });

        // Subscribe to status changes
        this.statusSubscription = this.auth.status$.subscribe(status => {
            this.logInInProgress = status;
            this.cdr.detectChanges();
        });

        if (this.isLoggedInEntra()) {
            // Show button to start over the login progress
            this.auth.updateStatus(true);
        }

        this.handleRouteParams();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    logInDisabled(): boolean {
        return !(this.clientId && this.tenantId);
    }

    handleRouteParams(): void {
        this.activatedRoute.paramMap.subscribe(async params => {
            this.clientId = params.get('clientId');
            this.tenantId = params.get('tenantId');

            if (this.clientId && this.tenantId) {
                try {
                    // Set clientId & tenantId for msal instance
                    await this.auth.configureMsalInstance(this.clientId, this.tenantId);
                } catch (error) {
                    console.error('Error configuring MSAL instance:', error);
                    this.auth.clearLocalStorage();
                }
            } else {
                this.entraLoginError = 'Client ID or Tenant ID is missing in URL';
            }
            this.saving.next(false);
        });
    }

    async login(): Promise<void> {
        this.saving.next(true);
        this.entraLoginError = '';

        try {
            const result = await this.auth.loginPopup();
            if (result) {
                console.log('Result loginPopup: ', result);
                this.auth.processLogin(result);
            }
        } catch (error) {
            this.entraLoginError = `Login failed: ${error}`;
        } finally {
            this.saving.next(false);
        }
    }

    async loginCapacitor(): Promise<void> {
        this.saving.next(true);
        this.entraLoginError = '';

        try {
            if (!MsAuthPlugin) {
                return;
            }

            const result = await MsAuthPlugin.login({
                clientId: this.clientId,
                tenant: this.tenantId,
                scopes: ['openid', 'profile', 'User.Read'],
                // The hash without /
                keyHash: 'VzSiQcXRmi2kyjzcA+mYLEtbGVs=',
            });

            if (!result.idToken) {
                console.log('Authentication error: idToken is not found')
                return;
            }

            const isLoginSuccessful = await this.auth.loginMicrosoft(result.idToken);

            if (isLoginSuccessful) {
                this.redirectToDefaultView = true;
            }
        } catch (error) {
            console.error('Authentication error: ', error);
            console.log('Trying to log out and login again...');

            await this.logoutCapacitor();
            this.loginCapacitor();
        } finally {
            this.saving.next(false);
        }
    }

    async logoutCapacitor(): Promise<void> {
        try {
            if (!MsAuthPlugin) {
                return;
            }

            const result = await MsAuthPlugin.logout({
                clientId: this.clientId,
                tenant: this.tenantId,
                // The hash without /
                keyHash: 'VzSiQcXRmi2kyjzcA+mYLEtbGVs=',
            });

            this.clearLocalStorage();
            this.cdr.detectChanges();
        } catch (error) {
            console.error('Logout error:', error);
        }
    }

    logout(): void {
        this.auth.logout();
        this.auth.clearLocalStorage();
    }

    isLoggedInEntra(): boolean {
        return this.auth.isLoggedIn();
    }

    clearLocalStorage(): void {
        localStorage.clear();
        this.logInInProgress = false;
    }

    toDefaultView(): void {
        void this.route.toDefaultView();
    }
}
