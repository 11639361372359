/** @format */

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { RPCService } from 'app/_services/rpc.service';
import { CoreService } from 'app/_services/core.service';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { DocumentTemplate, Process } from '@app/models';
import { environment } from '@app/env';

export type GroupInTemplate = 'activityLinks' | 'fieldMap' | 'fields' | 'images' | 'opts';
export type PropertyType =
    | 'decimalSeparator'
    | 'description'
    | 'field'
    | 'formatFieldValue'
    | 'label'
    | 'process'
    | 'staticActivityIds'
    | 'thousandSeparator'
    | 'toFixedDigits'
    | 'toFixedIds'
    | 'linkedActivitiesFilteredPhases'
    | 'value';

export interface FieldMap {
    staticActivityIds: string;
    images: {
        [key: string]: Image;
    };
    activityLinks: {
        [key: string]: LinkedField;
    };
    fields: {
        [key: string]: Field;
    };
}

export interface Field {
    label: string;
    value: string;
    description: string;
}

export interface LinkedField {
    label: string;
    process: string;
    field: string;
    description: string;
}

export interface Image {
    value: string;
    description: string;
}

export interface Editor {
    key?: string;
    label?: string;
    value?: string;
    description?: string;
    field?: string;
    type?: string;
    _id?: string;
    parentId?: string;
    process?: string;
    processUniqueId?: string;
    processName?: string;
    processCount?: number;
    activityName?: string;
    processFieldLabel?: string;
    url?: string;
}

export interface Opts {
    formatFieldValue: boolean;
    thousandSeparator: string;
    decimalSeparator: string;
    toFixedDigits: string;
    toFixedIds: string;
    linkedActivitiesFilteredPhases: string;
}

export interface LinkedActivitiesPhases {
    value: string;
    viewValue: string;
}

export interface LinkedActivitiesPhasesGroup {
    disabled?: boolean;
    processName: string;
    processId: string;
    linkedActivitiesPhases: LinkedActivitiesPhases[];
}

export interface FieldsWithFixedDigits {
    value: string;
    viewValue: string;
}

export interface FieldsWithFixedDigitsGroup {
    disabled?: boolean;
    name: string;
    fieldsWithFixedDigits: FieldsWithFixedDigits[];
}

export interface ProcessField {
    label: string;
    type: string;
    _id?: string;
    disabled?: boolean;
}

export interface Updated {
    value: boolean;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class ProcessEditorDocTemplatesService {
    updateLinkedActivities: Observable<boolean>;
    updateFields: Observable<boolean>;

    private updateLinkedActivitiesSubject = new BehaviorSubject<boolean>(false);
    private updateFieldsSubject = new BehaviorSubject<boolean>(false);
    private updated: Updated = {
        value: false,
        name: '',
    };

    constructor(
        private core: CoreService,
        private rpc: RPCService,
        private matSnackBar: MatSnackBar,
        private dialogHelper: DialogHelperService
    ) {
        this.init();
    }

    renderLinkedActivities() {
        this.updateLinkedActivitiesSubject.next(true);
    }

    renderFields() {
        this.updateFieldsSubject.next(true);
    }

    setUpdated(value: boolean, name: string) {
        this.updated.value = value;
        this.updated.name = name;
    }

    getUpdated() {
        return this.updated;
    }

    async setDocTemplateFieldProperty(
        processId: string,
        templateName: string,
        group: GroupInTemplate,
        key: string,
        property: PropertyType,
        value: any
    ) {
        try {
            await this.rpc.requestAsync('process.set_docTemplateFieldProperty', [
                { processId, name: templateName, group, key, property, value },
            ]);

            this.showSnackbar();
        } catch (error) {
            this.showError(error.msg);
            console.error('Error changing document template property value', error);
        }
    }

    async setDocTemplatePrintName(processId: string, templateId: string, printName: string) {
        try {
            await this.rpc.requestAsync('process.set_docTemplatePrintName', [processId, templateId, printName]);

            this.showSnackbar();
        } catch (error) {
            this.showError(error.msg);
            console.error('Error changing document template print name', error);
        }
    }

    async createDocTemplateField(processId: string, templateName: string, group: GroupInTemplate): Promise<void> {
        try {
            await this.rpc.requestAsync('process.create_docTemplateField', [{ processId, name: templateName, group }]);

            this.showSnackbar();
        } catch (error) {
            this.showError(error.msg);
            console.error('Error creating document template field', error);
        }
    }

    async deleteDocTemplateField(processId: string, templateName: string, group: GroupInTemplate, key: string): Promise<void> {
        try {
            await this.rpc.requestAsync('process.delete_docTemplateField', [{ processId, name: templateName, group, key }]);

            this.showSnackbar();
        } catch (error) {
            this.showError(error.msg);
            console.error('Error creating document template field', error);
        }
    }

    /**
     * Adds fields by process to display as mat-select
     *
     * @param process Process to add
     * @param processFieldsMap Contains the data to add to
     * @param activity  Optional. Add extra field to mat-select
     * @param processUniqueId Optional. Contains parent activitylink fieldId + process._id
     */
    addToProcessFieldsMap(
        process: Process,
        processFieldsMap: { [processId: string]: ProcessField[] },
        processUniqueId?: string,
        addTitle?: boolean
    ) {
        if (!process) {
            return;
        }

        const id = processUniqueId ? processUniqueId : process._id;

        if (processFieldsMap[id]) {
            return;
        }

        processFieldsMap[id] = [];
        // Add Title as first choise in dropdowns.
        if (addTitle) {
            const processTitle: ProcessField = {
                label: process.nameColumnText ? process.nameColumnText : 'Title',
                type: 'text',
                _id: '::name',
                disabled: false,
            };
            processFieldsMap[id].push(processTitle);
        }

        if (process.fieldsOrder) {
            process.fieldsOrder.forEach((fieldId: string) => {
                const field = process.fields[fieldId];

                if (field) {
                    const processField: ProcessField = {
                        label: field.label,
                        type: field.type,
                        _id: field._id,
                        disabled: false,
                    };
                    processFieldsMap[id].push(processField);
                }
            });
        } else {
            for (const key of Object.keys(process.fields)) {
                const field = process.fields[key];

                const processField: ProcessField = {
                    label: field.label,
                    type: field.type,
                    _id: field._id,
                    disabled: false,
                };
                processFieldsMap[id].push(processField);
            }
        }
    }

    /**
     * Disable process fields in mat-select
     *
     * @param processFieldsMap Contains data to disable
     * @param fieldMapData Contains data to search from
     */
    disableProcessFields(processFieldsMap: { [processId: string]: ProcessField[] }, fieldMapData: Editor[]) {
        for (const [key, processFields] of Object.entries(processFieldsMap)) {
            for (const processField of processFields) {
                let fieldInUse: Editor;

                if (key.length > 24) {
                    fieldInUse = fieldMapData.find(f => f._id === processField._id && f.processUniqueId === key);
                } else {
                    fieldInUse = fieldMapData.find(f => (f._id === processField._id && !f.parentId) || f.field === processField._id);
                }

                if (fieldInUse) {
                    processField.disabled = true;
                } else {
                    processField.disabled = false;
                }
            }
        }
    }

    async getFieldMapFields(
        fieldMap: FieldMap,
        process: Process,
        fieldMapFields: Editor[] = [],
        processFieldsMap: { [processId: string]: ProcessField[] },
        fieldMapStaticActivityIds: Editor[] = []
    ) {
        if (fieldMap.fields) {
            this.addToProcessFieldsMap(process, processFieldsMap, null, true);

            for (const key of Object.keys(fieldMap.fields)) {
                const field = fieldMap.fields[key];

                const editor: Editor = {
                    key,
                    label: field.label,
                    value: field.value,
                    description: field.description,
                };

                // Try to find label from field in process
                const steps = field.value.split('/');
                const step1: string = steps.shift();

                if (step1.startsWith('::')) {
                    const id = step1.substr(2, step1.length);
                    const processField = process.fields[id];

                    // Add Title
                    if (!processField && id === 'name') {
                        editor._id = '::name';
                        editor.process = process._id;
                        editor.processName = process.name;
                        editor.processFieldLabel = process.nameColumnText ? process.nameColumnText : 'Title';
                        fieldMapFields.push(editor);
                        continue;
                    }

                    if (!processField) {
                        fieldMapFields.push(editor);
                        continue;
                    }

                    if (processField.type === 'activitylink') {
                        const step2: string = steps.shift();

                        if (step2.startsWith('::')) {
                            const fieldId = step2.substr(2, step2.length);

                            if (fieldId === '' || fieldId === 'name') {
                                editor.processFieldLabel = processField.label;
                                editor.process = process._id;
                                editor.type = processField.type;
                                editor._id = processField._id;
                            }

                            for (const processId of processField.data) {
                                const processFromData = this.core.processById(processId);
                                const foundField = processFromData ? processFromData.fields[fieldId] : undefined;
                                this.addToProcessFieldsMap(processFromData, processFieldsMap);

                                if (foundField) {
                                    editor.processFieldLabel = `${processField.label} / ${foundField.label}`;
                                    editor.process = processFromData._id;
                                    editor.processName = processFromData.name;
                                    editor.type = foundField.type;
                                    editor._id = foundField._id;
                                    editor.parentId = id;
                                    editor.processUniqueId = id + processFromData._id;
                                    this.addToProcessFieldsMap(processFromData, processFieldsMap, id + processFromData._id);
                                    break;
                                }
                            }
                        }
                    } else {
                        editor.processFieldLabel = processField.label;
                        editor.process = process._id;
                        editor.processName = process.name;
                        editor.type = processField.type;
                        editor._id = processField._id;
                    }
                } // End Try to find label from field in process

                fieldMapFields.push(editor);
            }
        }

        /* Add info about static activities (activity located under another process)
           Static activity only reads data under same activity. Does not pick up data under activitylinks
           fieldMap.hasOwnProperty is used if a user removes the value in Statid ActivityIds. This make it
           possible to put the value back in textfield. */
        if (fieldMap.hasOwnProperty('staticActivityIds') && fieldMap.fields) {
            const activitiesNames: string[] = [];
            const processNames: string[] = [];
            const staticActivityIds = fieldMap.staticActivityIds.split(',');

            fieldMapStaticActivityIds.push({
                key: 'staticActivityIds',
                value: fieldMap.staticActivityIds,
                description: '',
                activityName: '',
                processName: '',
            });

            for (const staticActivityId of staticActivityIds) {
                if (staticActivityId === '') {
                    continue;
                }

                const activity = await this.getActivity(staticActivityId);

                if (!activity) {
                    continue;
                }

                const processFromActivity = this.core.processById(activity.process);
                this.addToProcessFieldsMap(processFromActivity, processFieldsMap, null, true);

                activitiesNames.push(activity.name);
                processNames.push(processFromActivity.name);

                // Replace Title with correct value (::name to staticActivityId)
                const staticProcess = processFieldsMap[activity.process];
                const staticField = staticProcess.find(f => f._id === '::name');
                if (staticField) {
                    staticField._id = staticActivityId;
                }

                // Name Field is not located under activity.fields
                const nameField = fieldMapFields.find(f => (f.value.startsWith('::') ? f.value.substr(2, 24) === activity._id : ''));

                if (nameField) {
                    nameField.activityName = activity.name;
                    nameField.processFieldLabel = activity.name;
                    nameField.processName = processFromActivity.name;
                    nameField.process = processFromActivity._id;
                    nameField.type = 'staticActivityId';
                    nameField._id = activity._id;
                }

                // Activity.fields
                for (const key of Object.keys(activity.fields)) {
                    const field = fieldMapFields.find(f => (f.value.startsWith('::') ? f.value.substr(2, 24) === key : ''));

                    if (field) {
                        const processField = processFromActivity.fields[field.value.substr(2, 24)];
                        field.activityName = activity.name;
                        field.processFieldLabel = processField.label;
                        field.processName = processFromActivity.name;
                        field.process = processFromActivity._id;
                        field.type = processField.type;
                        field._id = processField._id;
                    }
                }
            }

            fieldMapStaticActivityIds[0].activityName = activitiesNames.join(' - ');
            fieldMapStaticActivityIds[0].processName = processNames.join(' - ');
        }
    }

    async getFieldMapActivityLinks(fieldMap: FieldMap) {
        const fieldMapActivityLinks: Editor[] = [];

        if (!fieldMap.activityLinks) {
            return fieldMapActivityLinks;
        }

        for (const key of Object.keys(fieldMap.activityLinks)) {
            const activityLink = fieldMap.activityLinks[key];
            const process = this.core.processById(activityLink.process);

            const processCount = Object.values(fieldMap.activityLinks).filter(f => f.process === activityLink.process).length;

            const editor: Editor = {
                key,
                label: activityLink.label,
                field: activityLink.field,
                description: activityLink.description,
                process: activityLink.process,
                processName: process?.name ? process.name : '',
                processCount,
            };

            const fields = activityLink.field.split(';');
            const processField = fields[0] && process ? process.fields[fields[0]] : undefined;
            if (processField) {
                editor.processFieldLabel = processField.label;
                editor.type = processField.type;
            }

            fieldMapActivityLinks.push(editor);
        }

        return fieldMapActivityLinks;
    }

    async getActivity(activityId: string) {
        try {
            const activity = (await this.rpc.requestAsync('activities.load', [activityId])) as any;
            return activity;
        } catch (error) {
            console.error('Failed to fetch an activity', error);
            return null;
        }
    }

    async getTemplate(templateId: string) {
        try {
            const template = (await this.rpc.requestAsync('v3.template.get', [templateId])) as any;
            return template;
        } catch (error) {
            console.error('Failed to fetch template', error);
            return null;
        }
    }

    // Get image url
    getImageUrl(id: string): string {
        return `${environment.wsUrl}/image/hires/${id}`;
    }

    sortTemplates(templates: DocumentTemplate[] | undefined): { [key: string]: DocumentTemplate } {
        const sortedDocumentTemplates: { [key: string]: DocumentTemplate } = {};

        if (templates) {
            Object.keys(templates)
                .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
                .forEach(key => {
                    sortedDocumentTemplates[key] = templates[key];
                });
        }

        return sortedDocumentTemplates;
    }

    showSnackbar(message?: string): void {
        if (message) {
            this.matSnackBar.open(message, 'Close', { duration: 5500 });
        } else {
            this.matSnackBar.open('Changes saved', 'Close', { duration: 2500 });
        }
    }

    showError(errorMsg: string): void {
        const confirm = `Ok`;
        const content = errorMsg;
        const title = `Error`;

        this.dialogHelper.showError(confirm, content, title).pipe(take(1)).subscribe();
    }

    private init(): void {
        this.updateFields = this.updateFieldsSubject.asObservable();
        this.updateLinkedActivities = this.updateLinkedActivitiesSubject.asObservable();
    }
}
