<!-- @format -->
<div class="login-background">
    <div class="bubbles"></div>
    <form>
        <div class="entra-login-button-container--multiple-buttons">
            <app-hailer-logo></app-hailer-logo>
            <div class="flex-column--centered login-header">
                <h1 class="login-header">{{ 'public.login-page.log-in' | transloco }} Microsoft</h1>
            </div>
            <button
                *ngIf="!isMobile && !isLoggedInEntra()"
                (click)="login()"
                [disabled]="logInDisabled() || logInInProgress"
                data-cy="login-entra-button"
                mat-flat-button
                class="single-row-button primary-public-pages">
                {{
                    (saving | async) || logInInProgress
                        ? ('public.login-form.button_login_1' | transloco)
                        : ('public.login-form.button_login_2' | transloco)
                }}
            </button>
            <button
                *ngIf="isMobile && !isLoggedInEntra()"
                (click)="loginCapacitor()"
                [disabled]="logInDisabled() || logInInProgress"
                data-cy="login-entra-button"
                mat-flat-button
                class="single-row-button primary-public-pages">
                {{
                    (saving | async) || logInInProgress
                        ? ('public.login-form.button_login_1' | transloco)
                        : ('public.login-form.button_login_2' | transloco)
                }}
            </button>

            <button *ngIf="logInInProgress" (click)="clearLocalStorage()" mat-flat-button class="single-row-button primary-public-pages">
                {{ 'public.login-form.button_reset' | transloco }}
            </button>
            <button
                *ngIf="isLoggedInEntra() && isMobile"
                (click)="logoutCapacitor()"
                mat-flat-button
                class="single-row-button primary-public-pages">
                {{ 'public.login-form.sign_out' | transloco }}
            </button>
            <button *ngIf="isLoggedInEntra() && !isMobile" (click)="logout()" mat-flat-button class="single-row-button primary-public-pages">
                {{ 'public.login-form.sign_out' | transloco }}
            </button>
            <button *ngIf="redirectToDefaultView" (click)="toDefaultView()" mat-flat-button class="single-row-button primary-public-pages">
                {{ 'public.login-form.toDefaultView' | transloco }}
            </button>

            <ng-container *ngIf="entraLoginError">
                <span class="login-error">{{ 'public.login-form.login_error' | transloco }}</span>
                <span class="login-error">{{ entraLoginError }}</span>
            </ng-container>
        </div>
    </form>
</div>
