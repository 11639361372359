/** @format */

import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription,
    combineLatest,
    debounceTime,
    filter,
    lastValueFrom,
    pluck,
    takeUntil,
} from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';

import {
    Activity,
    Company,
    DiscussionFileResponse,
    DiscussionLink,
    File,
    PersonalSettings,
    Process,
    ProcessFieldType,
    Team,
    UserMap,
} from '@app/models';
import { PhaseCounts } from 'app/activities/activities.service';
import { EventsService } from 'app/events/events.service';
import { CopyActivitiesDialogComponent } from 'app/_dialogs/copy-activities-dialog/copy-activities-dialog.component';
import { DialogHelperService } from 'app/_dialogs/dialog-helper.service';
import { stripUndefined } from 'app/_helpers/util';
import { SETUpdateResult } from 'app/_models/activitySetUpdateResult';
import { CoreSignal } from 'app/_models/coreSignal.enum';
import {
    ActivitiesRemovedResponse,
    ActivityCopyOptions,
    ActivityFieldValue,
    ActivityInitFieldValues,
    ActivitySidenavActions,
    ActivityTabTypes,
    ActivityTemplate,
    ActivityTemplateActivity,
    ActivityTemplateField,
    ActivityTemplateOptions,
    LinkedFromGroupActivity,
    LinkedFromGroupMap,
    LinkedFromGroupPhaseMap,
    LinkedFromNextOptions,
    LinkedFromOverviewOptions,
    LinkedFromRefreshOptions,
    LinkedFromSearchOptions,
    LinkedFromPhaseLimits as LinkedPhaseLoadOptions,
    SetCreateArguments,
    SetEditMultipleArguments,
    SetViewArguments,
    SubheaderFieldGroupMap,
    ToSave,
    WorkflowStaticFields,
} from 'app/_models/v3-activity.model';
import { PermissionMap, PhasePermission, WorkflowPermissions } from 'app/_models/v3-permission.model';
import { V3DiscussionService } from 'app/_services/v3-discussion.service';
import { LicenseService } from 'app/_services/license.service';
import { RoutingService } from 'app/_services/routing.service';
import { RPCService } from 'app/_services/rpc.service';
import { SideNavService } from 'app/_services/side-nav.service';
import { TeamService } from 'app/_services/team.service';
import { CoreService } from '../_services/core.service';
import { V3EditMultipleHelperService } from './v3-edit-multiple-helper.service';
import { TranslateService } from 'app/_services/translate.service';
import { SnackBarService } from 'app/_services/snack-bar.service';

@Injectable()
export class V3ActivityViewContextService implements OnDestroy {
    /** Emits when activity is created or updated. Used to listen for changes in the whole sidenav stack */
    static activityUpdated = new Subject<{ state: 'created' | 'updated'; _id: string; workflowId: string; phaseId: string | undefined }>();

    errorsInSidenav = new BehaviorSubject<{
        duplicate?: boolean;
        invalidDetails?: boolean;
        detailsChanging?: boolean;
        invalidOptions?: boolean;
        invalidMap?: boolean;
        filesUploading?: boolean;
        activityFieldErrors?: { [activityId: string]: string[] };
    }>({
        duplicate: false,
        invalidDetails: false,
        detailsChanging: false,
        invalidOptions: false,
        invalidMap: false,
        filesUploading: false,
    });

    /**
     * Details of activity/activities to save,
     * these are edited in various components
     **/
    toSave: ToSave = {
        activity: {},
        activities: [],
        options: {},
    };

    /** Activity details gets drawn from this */
    template = new BehaviorSubject<ActivityTemplate | null>(null);

    phaseId: string | undefined;
    nextPhaseId = new BehaviorSubject<string | null>(null);
    /** Used when failing to move multiple activities to a phase with required field and then fixing failing activities */
    setNextPhase = new Subject<string>();

    activity = new BehaviorSubject<Activity | null>(null);
    workflow = new BehaviorSubject<Process | null>(null);
    action: ActivitySidenavActions;

    fieldOverrides = new BehaviorSubject<{ [fieldId: string]: any }>({});

    /** Enables editing for the whole sidenav */
    editing = new BehaviorSubject<boolean>(false);

    /** Informs sidenav is files are being added or removed */
    editingFiles = new BehaviorSubject<boolean>(false);

    /** Informs sidenav if location is being added or removed */
    editingLocation = new BehaviorSubject<boolean>(false);

    /** Closes the sidenav when emitted */
    popSidenav = new Subject<void>();

    /** FieldId, when editing is enabled focus on specified field  */
    focusOnField: string | null;

    linkedFromGroup = new BehaviorSubject<LinkedFromGroupMap>({});

    initFieldValues: ActivityInitFieldValues;

    highlightLinkedFromPhase = new BehaviorSubject<string | null>(null);

    currentTab = new BehaviorSubject<ActivityTabTypes | undefined>(undefined);

    overviewPeek: boolean;

    loadingLinkedFrom = new BehaviorSubject<boolean>(true);

    /** Collapses all linked phase panels */
    toggleLinkedPhasePanels = new Subject<'collapse' | 'expand'>();

    linkedPhaseLoadOptions: LinkedPhaseLoadOptions = {};

    v3Permissions = new BehaviorSubject<PermissionMap>({});
    /** True when setting/reloading sidenav data */
    loadingInfo = new BehaviorSubject<'initial' | 'reload' | null>(null);
    error = new BehaviorSubject<boolean>(false);

    updateDiscussionAttachments = new Subject<void>();
    removingActivity = false;

    subheaderFieldGroups = new BehaviorSubject<SubheaderFieldGroupMap>({});
    collapsedSubheaderIds = new BehaviorSubject<string[]>([]);
    hideHeaderImage = new BehaviorSubject<boolean>(false);
    modifierFiles = new BehaviorSubject<{ [fileId: string]: File }>({});

    /** Whether activity creator will join created activity or not */
    // inviteActivityCreator will be either undefined, true or false. So we need to flip its value when checking it
    joinCreated = false;

    /** Emits fieldId */
    fieldUpdated = new Subject<string>();

    private linkedFromSearchString: string;
    private linkedFromLimitIncrement = 50;
    private workflowUpdatedSubscription: Subscription;
    private discussionUpdatedSubscription: Subscription;
    private onDestroy = new Subject<void>();

    constructor(
        private zone: NgZone,
        public dialog: DialogHelperService,
        private core: CoreService,
        private rpc: RPCService,
        private sideNav: SideNavService,
        private v3Discussion: V3DiscussionService,
        private events: EventsService,
        private license: LicenseService,
        private router: RoutingService,
        private matDialog: MatDialog,
        private team: TeamService,
        private v3EditMultipleHelper: V3EditMultipleHelperService,
        private translocoService: TranslocoService,
        private translateService: TranslateService,
        private snackbar: SnackBarService,
    ) {
        combineLatest([this.core.networks, this.core.processes, this.core.user])
            .pipe(
                filter(() => !this.loadingInfo.value && !!this.workflow.value && !!this.activity.value),
                takeUntil(this.onDestroy),
                debounceTime(250)
            )
            .subscribe({
                next: () => this.setPermissions(),
            });

        V3ActivityViewContextService.activityUpdated.pipe(takeUntil(this.onDestroy)).subscribe({
            next: event => {
                switch (event.state) {
                    case 'created':
                        if (!this.activity.value || event._id === this.activity.value._id || this.currentTab.value !== 'linkedFrom') {
                            return;
                        }

                        this.highlightLinkedFromPhase.next(event.phaseId || null);
                        this.currentTab.next('linkedFrom');
                        break;
                    case 'updated':
                        const currentErrors = this.errorsInSidenav.value;
                        delete currentErrors?.activityFieldErrors?.[event._id];

                        if (!Object.keys(currentErrors?.activityFieldErrors || {}).length) {
                            delete currentErrors?.activityFieldErrors;
                        }

                        this.errorsInSidenav.next(currentErrors);
                        break;
                    default:
                        break;
                }
            },
        });

        this.rpc.signals
            .pipe(
                takeUntil(this.onDestroy),
                filter(() => !!this.activity.value?._id)
            )
            .subscribe({
                next: async signal => {
                    const meta = signal.meta;
                    const phaseId = meta.phase;
                    const workflowId = meta.processId;
                    const activityIds = Array.isArray(meta.activity_id) ? meta.activity_id : [meta.activity_id];

                    const linkedWorkflow = this.linkedFromGroup.value?.[workflowId];
                    let linkedPhase = linkedWorkflow?.phases?.[phaseId];

                    switch (signal.sig) {
                        case CoreSignal.ActivitiesCreated:
                            if (!phaseId || !workflowId) {
                                break;
                            }

                            await this.refreshLinkedActivities(workflowId, phaseId, activityIds, {
                                searchString: this.linkedFromSearchString,
                            });
                            break;
                        case CoreSignal.ActivitiesUpdated:
                            if (meta.removed && activityIds.includes(this.activity.value?._id)) {
                                this.error.next(true);
                                break;
                            }

                            const currentActivityUpdated = activityIds.includes(this.activity.value?._id);
                            if (currentActivityUpdated && !this.editing.value) {
                                await this.reloadSidenavInfo({ resetToSave: true });
                            }

                            if (!phaseId || !workflowId) {
                                break;
                            }
                            if (linkedWorkflow) {
                                this.refreshLinkedActivities(workflowId, phaseId, activityIds, {
                                    removeFromPhase: meta.prevPhase,
                                    searchString: this.linkedFromSearchString,
                                });

                                if (meta.removed && linkedPhase) {
                                    const currentLinkedGroup = this.linkedFromGroup.value;
                                    linkedPhase = currentLinkedGroup?.[workflowId]?.phases?.[phaseId];

                                    for (const removedActivityId of activityIds) {
                                        const removedIndex = linkedPhase?.activities?.findIndex(({ _id }) => _id === removedActivityId);
                                        if (removedIndex === undefined) {
                                            continue;
                                        }

                                        currentLinkedGroup?.[workflowId]?.phases?.[phaseId]?.activities.splice(removedIndex, 1);
                                    }

                                    this.linkedFromGroup.next(currentLinkedGroup);
                                }
                            }

                            this.updateLinkedActivityPriority(phaseId, meta.activity_id, meta.priority);
                            break;
                        case CoreSignal.ActivitiesFollow:
                            const activityId = activityIds[0];
                            if (!activityId || activityId !== this.activity.value?._id) {
                                break;
                            }

                            this.reloadSidenavInfo();
                            break;
                        default:
                            break;
                    }
                },
            });

        this.v3Discussion.v3Message.newMessages
            .pipe(
                takeUntil(this.onDestroy),
                filter(newMessages => {
                    const newMessagesDiscussionId = newMessages?.[0]?.discussion;
                    const activityDiscussionId = this.activity.value?.discussion;
                    return !!newMessagesDiscussionId && !!activityDiscussionId && newMessagesDiscussionId === activityDiscussionId;
                }),
                debounceTime(500)
            )
            .subscribe({
                next: () => this.updateDiscussionAttachments.next(),
            });

        this.v3EditMultipleHelper.selectedActivities
            .pipe(
                takeUntil(this.onDestroy),
                filter(() => this.action === 'editMultiple')
            )
            .subscribe({
                next: activities => {
                    const parsedActivities = activities.map(({ _id, name }) => ({ _id, name }));

                    this.toSave.activities = parsedActivities;

                    // Updating activity field errors object in a case where activity with field errors was deselected
                    const activityIdSet = new Set(activities.map(({ _id }) => _id));
                    const currentErrors = this.errorsInSidenav.value;

                    for (const activityId in currentErrors.activityFieldErrors) {
                        if (!activityId) {
                            continue;
                        }

                        if (activityIdSet.has(activityId)) {
                            continue;
                        }

                        delete currentErrors.activityFieldErrors[activityId];
                    }

                    if (!Object.keys(currentErrors.activityFieldErrors || {})?.length) {
                        delete currentErrors.activityFieldErrors;
                    }

                    this.errorsInSidenav.next(currentErrors);
                },
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    /** @deprecated This will be deprecated at some point in favour of new activity update endpoints */
    followActivity(activityId: string): Observable<any> {
        return this.rpc.request('activities.follow', [activityId]).pipe(map(data => data));
    }

    /** @deprecated This will be deprecated at some point as in favour of function fields and sequence number */
    async updatePrefixedName() {
        if (this.action !== 'create' || !this.workflow?.value?.enablePredefinedName) {
            return;
        }

        let suffix = 0;
        try {
            const response = (await this.rpc.requestAsync('process.get_next_prefix_id', [this.workflow.value._id])) as {
                latestSuffix: number;
            };

            suffix = response.latestSuffix;
        } catch (error) {
            console.error('Failed to fetch latest predefined name');
        }

        const predefinedName = `${this.workflow.value.predefinedNamePrefix}-${suffix}`;
        this.toSave.activity.name = predefinedName;
    }

    get hasLinkedActivities() {
        let totalActivities = 0;
        for (const workflowId in this.linkedFromGroup.value) {
            if (!workflowId) {
                continue;
            }
            const workflow = this.linkedFromGroup.value[workflowId];
            totalActivities = totalActivities + (workflow?.activityCount || 0);
        }
        return !!totalActivities;
    }

    /** Sets sidenav info */
    async initializeSidenavInfo(args: SetCreateArguments & SetEditMultipleArguments & SetViewArguments): Promise<void> {
        if (this.loadingInfo.value) {
            return;
        }

        this.loadingInfo.next('initial');
        this.workflowUpdatedSubscription?.unsubscribe();
        this.discussionUpdatedSubscription?.unsubscribe();

        this.initFieldValues = args.initFieldValues || {};

        if (args.activityId) {
            // Fetch the activity if activityId is defined
            try {
                const activity = await this.getActivity(args.activityId);
                this.phaseId = activity.currentPhase;
                // Pick workflowId from activity, should not need to be passed at all...
                args.workflowId = activity.process;
                this.activity.next(activity);
                this.error.next(false);
            } catch (error) {
                this.error.next(true);
                console.error('Failed to fetch an activity', error);
            }
        }

        void this.collectFileIds();

        if (args.workflowId) {
            await this.setWorkflowAndTemplate(args.workflowId, args.phaseId ? args.phaseId : this.phaseId);
        }

        if (!this.workflow.value && this.activity.value) {
            console.warn('Attempting to find workflow with activity data');
            // If workflow is still undefined but activity is not, set the workflow from activity info
            await this.setWorkflowAndTemplate(this.activity.value.process, this.activity.value.currentPhase);
        }

        if (!this.workflow.value) {
            this.loadingInfo.next(null);
            this.error.next(true);
            return void console.error('Failed to find the workflow');
        }

        void this.setLinkedFrom();

        await this.setPermissions();

        // Default to create action if activity is not defined
        const defaultAction = this.activity.value ? 'view' : 'create';
        this.action = args.action ? args.action : defaultAction;

        this.focusOnField = this.action === 'create' ? 'nameField' : null;
        this.editing.next(this.action === 'create' || this.action === 'editMultiple' || !!args.editing);
        this.overviewPeek = args.overviewPeek || false;

        // Setting to save initial data
        this.toSave = {
            activity: stripUndefined({
                // Id is not defined when creating activity
                _id: this.activity.value?._id ? this.activity.value?._id : undefined,
                // We want to edit fields in the options object when the action is editMultiple
                fields: this.action !== 'editMultiple' ? {} : undefined,
            }),
            activities: args.activities || [],
            options: stripUndefined({
                // We want to edit fields in the options object when the action is editMultiple
                fields: this.action !== 'editMultiple' ? undefined : {},
                phaseId: this.workflow.value.enableUnlinkedMode ? this.phaseId : undefined,
            }),
        };

        void this.updatePrefixedName();

        this.listenToWorkflowChanges(this.workflow.value.cid, this.workflow.value._id, this.phaseId);

        if (args.discussionInfo) {
            this.toSave.options.followerIds = Object.assign([], args.discussionInfo.followers);
            this.toSave.options.discussionId = args.discussionInfo.id;

            this.initFieldValues.nameField = { value: args.discussionInfo.name };
        }

        this.joinCreated = this.workflow.value?.inviteActivityCreator || this.workflow.value?.inviteActivityCreator === undefined;
        if (this.action === 'create' && this.workflow.value.enableMessenger && this.joinCreated) {
            if (!this.toSave.options.followerIds) {
                this.toSave.options.followerIds = [];
            }

            this.toSave.options.followerIds.unshift(this.me._id);
        }

        this.loadingInfo.next(null);

        if (args.nextPhaseId) {
            this.setNextPhase.next(args.nextPhaseId);
        }
    }

    async setPermissions() {
        const workspaceId = this.workflow.value?.cid;
        if (!workspaceId) {
            console.warn('no workspace id');
            return;
        }

        try {
            this.v3Permissions.next((await this.getPermissions(this.core.user.value._id, workspaceId)) || {});
        } catch (error) {
            console.error('Failed to get permissions!', error);
        }
    }

    async collectFileIds() : Promise<void> {
        const activity = this.activity.value;
        if (!activity) {
            return;
        }
        const temporaryFieldIds = new Set<string>();

        Object.values(activity.fields).forEach((field : any ) => {
            if (field?.type !== 'text') {
                return;
            }

            try {
                const fileIds = JSON.parse(field.value);
                fileIds.forEach((fileId: string) => {
                    temporaryFieldIds.add(fileId);
                });
            } catch (error) {
                // This is not a valid JSON value, skip
            }
        });
        const files = await this.rpc.requestAsync('files.list', [Array.from(temporaryFieldIds)]);
        this.modifierFiles.next(files);
    }

    async reloadSidenavInfo(options?: { resetToSave?: boolean }) {
        if (this.loadingInfo.value) {
            return;
        }

        this.loadingInfo.next('reload');

        this.fieldOverrides.next({});

        const currentErrors = this.errorsInSidenav.value;
        for (const errorKey in currentErrors) {
            if (!errorKey) {
                continue;
            }

            currentErrors[errorKey] = false;
        }

        this.errorsInSidenav.next(currentErrors);

        try {
            if (this.activity.value) {
                this.activity.next(await this.getActivity(this.activity.value._id));
                void this.collectFileIds();
                this.phaseId = this.activity.value.currentPhase;
                this.error.next(false);
            }

            if (!this.workflow.value?._id) {
                return void console.warn('no workflow id');
            }

            await this.setWorkflowAndTemplate(this.workflow.value._id, this.phaseId);
        } catch (error) {
            this.error.next(true);
            console.error('Failed to reload info', error);
        }

        if (!options?.resetToSave) {
            this.loadingInfo.next(null);
            return;
        }

        this.editing.next(this.action === 'create' || this.action === 'editMultiple');

        this.toSave = {
            activity: {
                _id: this.activity.value?._id ? this.activity.value?._id : undefined,
            },
            activities: this.toSave.activities,
            options: {},
        };

        this.loadingInfo.next(null);
    }

    async setLinkedFrom() {
        this.loadingLinkedFrom.next(true);

        const linkableToActivityId = this.activity.value?._id;

        if (!linkableToActivityId) {
            return;
        }

        try {
            const options: LinkedFromOverviewOptions = {
                activityLimit: 10,
                includeEmptyWorkflows: true,
            };

            this.linkedFromGroup.next(await this.linkedFromOverview(linkableToActivityId, options));
        } catch (error) {
            console.error('Failed to fetch linked activities!', error);
        }

        this.loadingLinkedFrom.next(false);
    }

    async loadNextLinkedActivities(workflowId: string, phaseId: string, fromActivityId: string): Promise<void> {
        if (!this.activity.value?._id) {
            return void console.warn('no activity id set');
        }

        const activities = await this.linkedFromNext(this.activity.value._id, fromActivityId, {
            activityLimit: this.linkedFromLimitIncrement,
        });

        const currentLinked = this.linkedFromGroup.value;
        const currentPhase = currentLinked?.[workflowId]?.phases?.[phaseId];
        if (!currentPhase) {
            return;
        }

        currentPhase.activities = currentPhase.activities.concat(activities);
        this.linkedFromGroup.next(currentLinked);
        return;
    }

    async searchLinkedActivities(searchString?: string) {
        this.linkedFromSearchString = searchString || '';

        if (!searchString) {
            return this.setLinkedFrom();
        }

        const activityId = this.activity.value?._id;
        if (!activityId) {
            console.warn('No activity id to search linked ones with');
            return;
        }

        const searchResult = await this.linkedFromSearch(activityId, searchString, { activityLimit: 10 });
        this.linkedFromGroup.next(searchResult);
    }

    async refreshLinkedActivities(
        workflowId: string,
        phaseId: string,
        activityIds: string[],
        options?: {
            removeFromPhase?: string;
            searchString?: string;
        }
    ): Promise<void> {
        const activityId = this.activity.value?._id;
        if (!activityId) {
            console.warn('No activity id to refresh linked ones with');
            return;
        }

        const refresh = await this.linkedFromRefresh(activityId, phaseId, {
            activityIds,
            searchString: options?.searchString,
        });

        const currentLinkedGroup = this.linkedFromGroup.value;

        if (!refresh[workflowId]) {
            delete currentLinkedGroup[workflowId];
            this.linkedFromGroup.next(currentLinkedGroup);
            return;
        }

        const currentPhases: LinkedFromGroupPhaseMap = {};
        Object.assign(currentPhases, currentLinkedGroup[workflowId]?.phases || {});

        let activities: LinkedFromGroupActivity[] = [];
        Object.assign(activities, currentPhases[phaseId]?.activities || []);

        const refreshedActivities = refresh?.[workflowId]?.phases?.[phaseId]?.activities || [];

        // If activity cannot be found in the refreshed activities it is probably unlinked or removed so lets remove it from the list
        const refreshedActivitiesIds = new Set(refreshedActivities.map(({ _id }) => _id));
        const removedActivityIds = new Set(activityIds.filter(activityId => !refreshedActivitiesIds.has(activityId)));

        if (removedActivityIds.size) {
            activities = activities.filter(({ _id }) => !removedActivityIds.has(_id));
        }

        for (const refreshedActivity of refreshedActivities) {
            if (options?.removeFromPhase) {
                const removeIndex = currentPhases[options?.removeFromPhase]?.activities.findIndex(
                    ({ _id }) => _id === refreshedActivity._id
                );

                const removeFromPhase = currentPhases[options.removeFromPhase];
                if (removeIndex !== undefined && removeFromPhase?.activityCount) {
                    removeFromPhase.activities.splice(removeIndex, 1);
                    removeFromPhase.activityCount--;
                    if (!removeFromPhase?.activities.length) {
                        delete currentPhases[options?.removeFromPhase];
                    }
                }
            }

            const activityIndex = activities.findIndex(({ _id }) => _id === refreshedActivity._id);
            if (activityIndex < 0) {
                const latestLoaded = activities.length === currentPhases[phaseId]?.activityCount;
                const movedInLoadedActivities = refreshedActivity.index <= activities.length;

                if (latestLoaded || !currentPhases[phaseId] || movedInLoadedActivities) {
                    // Add activity to the end of the array if latest activity is already loaded
                    activities.splice(refreshedActivity.index, 0, refreshedActivity);
                    continue;
                }
            }

            activities.splice(activityIndex, 1);
            activities.splice(refreshedActivity.index, 0, refreshedActivity);
        }

        const refreshedWorkflow = refresh[workflowId];
        const refreshedPhase = refreshedWorkflow?.phases?.[phaseId];

        if (refreshedWorkflow && currentPhases) {
            refreshedWorkflow.phases = currentPhases;
            currentLinkedGroup[workflowId] = refreshedWorkflow;

            const phases = currentLinkedGroup[workflowId]?.phases;
            if (!refreshedPhase) {
                delete phases?.[phaseId];
            } else if (phases) {
                refreshedPhase.activities = activities;
                phases[phaseId] = refreshedPhase;
            }
        }

        this.linkedFromGroup.next(this.ensureLinkedFromGroupOrder(currentLinkedGroup));
        return;
    }

    getTemplate(workflowId: string, phaseId: string): Promise<ActivityTemplate> {
        const options = [workflowId, phaseId];
        return this.rpc.requestAsync('v3.activity.template.create', options) as Promise<ActivityTemplate>;
    }

    create(workflowId: string, activity: ActivityTemplateActivity, options: ActivityTemplateOptions): Promise<Activity> {
        return this.rpc.requestAsync('v3.activity.create', [workflowId, activity, options]) as Promise<Activity>;
    }

    createMany(workflowId: string, activities: ActivityTemplateActivity[], options: ActivityTemplateOptions): Promise<any> {
        return this.rpc.requestAsync('v3.activity.createMany', [workflowId, activities, options]) as Promise<any>;
    }

    update(activities: ActivityTemplateActivity[], options: ActivityTemplateOptions): Promise<number> {
        return this.rpc.requestAsync('v3.activity.updateMany', [activities, options]) as Promise<number>;
    }

    updateOwnerUser(activityIds: string[], userId: string): Promise<SETUpdateResult> {
        return this.rpc.requestAsync('activities.set_update', [{ activities: activityIds, owner: userId }]) as Promise<SETUpdateResult>;
    }

    evaluate(activity: ActivityTemplateActivity, options: ActivityTemplateOptions, processId: string): Promise<any> {
        const fields: { [fieldId: string]: ActivityFieldValue } = {};
        if (activity.fields) {
            Object.entries(activity.fields).forEach(([fieldId, value]) => (fields[fieldId] = value));
        }

        if (options.fields) {
            Object.entries(options.fields).forEach(([fieldId, value]) => (fields[fieldId] = fields[fieldId] || value));
        }

        const stripped = {
            _id: activity._id,
            name: activity.name,
            fields,
            phaseId: options.phaseId,
            processId,
        };

        return this.rpc.requestAsync('v3.activity.evaluateMany', [stripped]) as Promise<any>;
    }

    getUserName(userId: string): string {
        const user = this.core.getUser(userId);
        return user?.display_name;
    }

    async getUserNameAsync(userId: string): Promise<string> {
        const user = await this.core.getUserAsync(userId);
        return user?.display_name;
    }

    get unknownUsers(): BehaviorSubject<UserMap> {
        return this.core.unknownUsers;
    }

    async getTeamName(teamId: string): Promise<string> {
        const workspaceId = this.workflow.value?.cid;
        if (!workspaceId) {
            console.warn('No workspace id to fetch team name with');
            return 'Unknown Team';
        }

        const networkTeams = this.core.teams.value?.[workspaceId];
        let teamName = networkTeams?.[teamId]?.name;

        if (!teamName) {
            try {
                const team = await this.team.loadTeam(teamId);
                teamName = team?.name || 'Unknown Team';
            } catch (error) {
                console.warn('Failed to fetch team', error);
            }

            return teamName || 'Unknown Team';
        }

        return teamName || 'Unknown Team';
    }

    getNetwork(networkId: string): Company | undefined {
        return this.core.networks.value[networkId];
    }

    /** Routes user to workflow view and opens the activity sidenav */
    redirectToActivity(activityId: string) {
        this.router.navigate(['activities', 'activity', activityId]);
    }

    get me(): PersonalSettings {
        return this.core.user.value;
    }

    get isWorkspaceGuest(): boolean {
        const workspaceId = this.workflow.value?.cid;
        if (!workspaceId) {
            return false;
        }

        return this.v3Permissions.value?.[workspaceId]?.workspace?.isGuest || false;
    }

    get fileTaggingLicense(): boolean {
        return this.license.hasFileTagging();
    }

    getFieldLabel(workflowId: string, fieldId: string): string {
        const workspaceId = this.workflow.value?.cid || '';
        const workflow = this.core.processes.value?.[workspaceId]?.[workflowId] || this.core.processById(workflowId);
        if (!workflow) {
            return '';
        }

        return workflow.fields[fieldId]?.label || '';
    }

    /**
     * Checks if field can be found in workflows first phase.
     * Defaults to first phase
     **/
    isFieldInPhase(workflowId: string, fieldId: string, phaseId?: string): boolean {
        const linkedWorkflow = this.core.processById(workflowId);
        phaseId = phaseId || linkedWorkflow?.phasesOrder?.[0];
        if (!linkedWorkflow || !phaseId) {
            return false;
        }

        const phase = linkedWorkflow.phases[phaseId];
        if (!phase) {
            return false;
        }

        return phase.fields.includes(fieldId) && this.canPhaseBeEdited(workflowId, phaseId);
    }

    getWorkflow(workflowId: string): Process {
        return this.core.processById(workflowId);
    }

    /**
     * Returns the first phase where field appears,
     * if workflow is dataset check for first phase that can be edited
     **/
    getFirstPhaseFieldAppearsIn(workflowId: string, fieldId: string): string | undefined {
        const linkedWorkflow = this.core.processById(workflowId);
        if (!linkedWorkflow) {
            return;
        }

        const isDataset = linkedWorkflow.enableUnlinkedMode;

        for (const phaseId of linkedWorkflow.phasesOrder) {
            const phase = linkedWorkflow.phases[phaseId];
            if (!phase?.fields.includes(fieldId)) {
                continue;
            }

            if (isDataset && !this.canPhaseBeEdited(workflowId, phaseId)) {
                continue;
            }

            return phase._id;
        }
    }

    /** Returns fields that are in phases where user can create activities */
    creatableLinkableFields(workflowId: string, phaseId?: string): string[] {
        const linkedWorkflow = this.linkedFromGroup.value[workflowId];
        if (!linkedWorkflow) {
            return [];
        }

        const linkableFields = linkedWorkflow.linkableFields || [];

        if (phaseId) {
            if (!this.canPhaseBeEdited(workflowId, phaseId)) {
                return [];
            }

            const workflow = this.core.processById(workflowId);
            if (!workflow) {
                return [];
            }

            const phase = workflow.phases?.[phaseId];
            if (!phase) {
                return [];
            }

            return linkableFields.filter(fieldId => phase.fields.includes(fieldId));
        }

        if (linkedWorkflow.dataset) {
            return linkableFields.filter(fieldId => !!this.getFirstPhaseFieldAppearsIn(workflowId, fieldId));
        }

        return linkableFields.filter(fieldId => this.isFieldInPhase(workflowId, fieldId));
    }

    canCreateLinkedActivityInFirstPhase(workflowId: string): boolean {
        const linkableFields = this.creatableLinkableFields(workflowId);
        const linkedWorkflow = this.core.processById(workflowId);

        if (!linkedWorkflow) {
            return false;
        }

        const isDataset = linkedWorkflow.enableUnlinkedMode;
        if (isDataset) {
            return !!linkableFields?.length;
        }

        const firstPhaseId = linkedWorkflow?.phasesOrder?.[0];
        if (!firstPhaseId) {
            return false;
        }

        const canCreateInFirstPhase = this.phasePermissions(linkedWorkflow?.cid, workflowId, firstPhaseId)?.permission === 'edit' || false;

        if (!canCreateInFirstPhase) {
            return false;
        }

        const firstPhaseFields = linkedWorkflow?.phases?.[firstPhaseId]?.fields;

        if (!linkableFields?.length || !firstPhaseFields?.length) {
            return false;
        }

        for (const linkableField of linkableFields) {
            const fieldInFirstPhase = firstPhaseFields.includes(linkableField);

            if (fieldInFirstPhase) {
                return true;
            }
        }

        return false;
    }

    async updateFollowers(activities: { _id: string }[], followers: { [uid: string]: boolean | null }): Promise<any> {
        /* The new activity update endpoint does not support adding followers with view only permission,
           so we need to use the old add_followers endpoint in that case */
        if (!this.canBeEdited && !this.isWorkspaceGuest) {
            /** Includes only users that are being added to the activity */
            const invites = Object.keys(followers).filter(followerId => !!followers[followerId]);
            for (const activity of activities) {
                this.addFollowers(activity._id, invites);
            }
            return;
        }

        return this.rpc.requestAsync('v3.activity.updateMany', [activities, { followers }]);
    }

    /** @deprecated Will be removed once the v3 activity update endpoint supports inviting people with view only permission */
    async addFollowers(activityId: string, followerIds: string[]) {
        return this.rpc.requestAsync('activities.add_followers', [activityId, followerIds]);
    }

    getDiscussionFiles(
        discussionId: string,
        opts?: { limit?: number; skip?: number; search?: string; reverse?: boolean; sortBy?: 'created' | 'name' }
    ): Promise<DiscussionFileResponse> {
        return lastValueFrom(this.v3Discussion.loadFiles(discussionId, opts));
    }

    getDiscussionLinks(discussionId: string): Promise<DiscussionLink[]> {
        return lastValueFrom(this.v3Discussion.links(discussionId));
    }

    getUserPictureUrl(userId: string): string {
        const user = this.core.getUser(userId);
        return user?.default_profilepic || '';
    }

    getPhaseName(workflowId: string, phaseId: string): string {
        if (!workflowId || !phaseId) {
            return 'n/a';
        }

        const workflow = this.core.processById(workflowId);
        return workflow?.phases?.[phaseId]?.name || 'n/a';
    }

    userTeams(uid: string): Team[] {
        return this.team.getUserTeams(uid) || [];
    }

    linkedFromOverview(linkableToActivityId: string, options: LinkedFromOverviewOptions): Promise<LinkedFromGroupMap> {
        const updateArray = [linkableToActivityId, options];
        return this.rpc.requestAsync('v3.activity.linkedFrom.overview', updateArray) as Promise<LinkedFromGroupMap>;
    }

    linkedFromNext(activityId: string, fromActivityId: string, options: LinkedFromNextOptions): Promise<LinkedFromGroupActivity[]> {
        return this.rpc.requestAsync('v3.activity.linkedFrom.next', [activityId, fromActivityId, options]) as Promise<
            LinkedFromGroupActivity[]
        >;
    }

    linkedFromRefresh(activityId: string, phaseId: string, options: LinkedFromRefreshOptions): Promise<LinkedFromGroupMap> {
        return this.rpc.requestAsync('v3.activity.linkedFrom.refresh', [activityId, phaseId, options]) as Promise<LinkedFromGroupMap>;
    }

    linkedFromSearch(activityId: string, searchString: string, options: LinkedFromSearchOptions): Promise<LinkedFromGroupMap> {
        return this.rpc.requestAsync('v3.activity.linkedFrom.search', [activityId, searchString, options]) as Promise<LinkedFromGroupMap>;
    }

    enableEditMode(fieldId: string) {
        if (!this.canBeEdited) {
            return;
        }

        this.focusOnField = fieldId;
        this.editing.next(true);
    }

    removeActivities(activityIds: string[]): Promise<ActivitiesRemovedResponse> {
        return this.rpc.requestAsync('activities.remove', [activityIds]) as Promise<ActivitiesRemovedResponse>;
    }

    async copyActivities() {
        const workflow = this.workflow.value;
        const predefinedName = !!workflow?.enablePredefinedName || !!workflow?.nameFunctionEnabled;

        let activities: {
            _id: string;
            name: string;
        }[] = [];

        if (this.action === 'editMultiple') {
            activities = this.toSave.activities;
        } else if (this.activity.value) {
            activities = [{ _id: this.activity.value._id, name: this.activity.value.name }];
        }

        const hasLinkedActivities = !!Object.keys(this.linkedFromGroup.value || {}).length;
        // TODO Edit multiple
        if (!hasLinkedActivities && predefinedName) {
            const ids = activities.map(({ _id }) => _id || '');
            const options: ActivityCopyOptions = {
                workspaceId: this.workflow.value?.cid,
                advanced: false,
                rename: activities.map(({ _id, name }) => ({ [_id]: name }))[0],
            };

            try {
                const copied = await this.copy(ids, options);
                this.snackbar.openActivityCopySnackBar(copied.length, workflow.enableUnlinkedMode);
            } catch (error) {
                console.error('Failed to copy activity: ', error);
            }
            return;
        }

        this.zone.run(() => {
            this.matDialog
                .open(CopyActivitiesDialogComponent, {
                    data: {
                        activities,
                        processId: workflow?._id,
                        enableUniqueName: workflow?.enableUniqueName,
                        disableNameEditing: predefinedName,
                        linkedActivities: this.action === 'editMultiple' || hasLinkedActivities,
                        isDataset: this.workflow.value?.enableUnlinkedMode,
                    },
                })
                .afterClosed()
                .pipe(
                    takeUntil(this.onDestroy),
                    filter(data => !!data)
                )
                .subscribe({
                    next: async data => {
                        try {
                            const options = data?.renamed || {};
                            options.workspaceId = this.workflow.value?.cid;

                            const copied = await this.copy(data?.activities, options);
                            this.snackbar.openActivityCopySnackBar(copied.length, workflow?.enableUnlinkedMode);
                        } catch (error) {
                            console.error('Failed to copy activities', error);
                        }
                    },
                });
        });
    }

    phaseCounts(workflowId: string): Promise<PhaseCounts> {
        return this.rpc.requestAsync('activities.phase_count', [workflowId]) as Promise<PhaseCounts>;
    }

    isStaticField(fieldType: ProcessFieldType): boolean {
        return WorkflowStaticFields.includes(fieldType);
    }

    closeDiscussionCreate() {
        this.v3Discussion.closeCreateMenu.next();
    }

    closeEventCreate() {
        this.events.closeCreateMenu.next();
    }

    get canBeEdited(): boolean {
        const workflowId = this.workflow.value?._id;
        const workspaceId = this.workflow.value?.cid;

        if (!workspaceId || !workflowId || (!this.workflow.value?.enableGuestEditing && this.isWorkspaceGuest)) {
            return false;
        }

        const nextPhaseId = this.nextPhaseId.value;
        const inCreateMode = this.action === 'create';
        const isDataset = this.workflow.value?.enableUnlinkedMode;

        const phaseId = nextPhaseId && inCreateMode && isDataset ? nextPhaseId : this.phaseId;

        if (!phaseId) {
            return false;
        }

        return this.phasePermissions(workspaceId, workflowId, phaseId)?.permission === 'edit' || false;
    }

    canPhaseBeEdited(workflowId: string, phaseId: string): boolean {
        if (!workflowId || !phaseId) {
            return false;
        }
        const workflow = this.core.processById(workflowId);
        return this.phasePermissions(workflow?.cid, workflowId, phaseId)?.permission === 'edit' || false;
    }

    canAccessPhase(workflowId: string, phaseId: string): boolean {
        const workflow = this.core.processById(workflowId);
        return this.phasePermissions(workflow?.cid, workflowId, phaseId)?.hasAccess || false;
    }

    valueChanged(oldValue: any, newValue: any, fieldId: string) {
        if (oldValue === undefined || oldValue === null) {
            return newValue !== undefined && newValue !== null;
        }

        const type = this.workflow.value?.fields?.[fieldId]?.type;

        switch (type) {
            case 'daterange':
            case 'timerange':
            case 'datetimerange':
                const differentStart = oldValue.start !== newValue?.start;
                const differentEnd = oldValue.end !== newValue?.end;
                return differentStart || differentEnd;
            case 'activitylink':
                return !this.isWorkspaceGuest && oldValue._id !== newValue;
            case 'country':
                return oldValue.code !== newValue?.code;
            case 'users':
            case 'teams':
                return !this.isWorkspaceGuest && oldValue !== newValue;
            default:
                return oldValue !== newValue;
        }
    }

    get isWorkflowAdmin(): boolean {
        const workspaceId = this.workflow.value?.cid;
        const workflowId = this.workflow.value?._id;
        if (!workflowId || !workspaceId) {
            return false;
        }

        return this.workflowPermissions(workspaceId, workflowId)?.isAdmin || false;
    }

    get isWorkspaceAdmin(): boolean {
        const workspaceId = this.workflow.value?.cid;
        if (!workspaceId) {
            return false;
        }

        const workspacePermissions = this.workspacePermissions(workspaceId);
        return workspacePermissions?.workspace?.isAdmin || false;
    }

    get isWorkspaceOwner(): boolean {
        const workspaceId = this.workflow.value?.cid;
        if (!workspaceId) {
            return false;
        }

        const workspacePermissions = this.workspacePermissions(workspaceId);
        return workspacePermissions?.workspace?.isOwner || false;
    }

    get hasChanges(): boolean {
        if (!this.editing.value) {
            return false;
        }

        const activity = this.toSave.activity;
        const options = this.toSave.options;

        const changesInActivityFiles = activity.fileIds?.length;
        const changesInActivityFields = Object.keys(activity.fields || {}).length;
        let changesInActivityName: boolean | string | undefined = activity.name;
        const changesInActivityLocation = activity.location || activity.location === null;

        if (this.template.value?.name?.functionEnabled) {
            const activityName = this.activity?.value?.name;
            const toSaveName = this.toSave.activity.name;
            const overrideName = this.fieldOverrides.value.nameField;
            if (activityName !== toSaveName && overrideName != null) {
                changesInActivityName = true;
            } else {
                changesInActivityName = this.valueChanged(activityName, toSaveName, 'nameField');
            }
        }

        const changesInActivity =
            !!changesInActivityFiles || !!changesInActivityFields || !!changesInActivityName || !!changesInActivityLocation;

        if (changesInActivity) {
            return true;
        }

        const changesInOptionFiles = Object.keys(options.files || {}).length;
        const changesInOptionFields = Object.keys(options.fields || {}).length;
        const changesInOptionFollowers = options.followerIds?.length || Object.keys(options?.followers || {}).length;
        const changesInOptionLocation = options.location || options.location === null;
        const changesInOptionPhaseId = options.phaseId;
        const changesInOptionTeamId = options.teamId;
        const changesInOptionUserId = options.userId;
        const changesInOptionDiscussionId = options.discussionId;

        const changesInOptions =
            !!changesInOptionFiles ||
            !!changesInOptionFields ||
            !!changesInOptionFollowers ||
            !!changesInOptionLocation ||
            !!changesInOptionPhaseId ||
            !!changesInOptionTeamId ||
            !!changesInOptionUserId ||
            !!changesInOptionDiscussionId;

        return changesInOptions;
    }

    setSubheaderFieldGroups(fields: ActivityTemplateField[]) {
        if (!fields?.length) {
            return;
        }

        const collapsed = this.collapsedSubheaderIds.value;
        const group = this.subheaderFieldGroups.value || {};

        /** Field id of an subheader field currently in the process of grouping */
        let currentSubheaderFieldId: string | undefined;

        for (const field of fields) {
            if (field.subtype !== 'subheader' && !currentSubheaderFieldId) {
                /* We don't have to do anything here.
                   These are fields before any subheader field */
                continue;
            }

            if (field.subtype === 'subheader') {
                /* Set the current subheader field.
                   This is set again when we run into another subheader field */
                currentSubheaderFieldId = field.id;
                const fieldAlreadyExisting = !!this.subheaderFieldGroups.value[field.id];
                if (fieldAlreadyExisting || !this.loadingInfo.value) {
                    /* Collapse only when loading info
                       Collapse only when field is not already visible */
                    continue;
                }

                if (field.collapsedByDefault && !collapsed.includes(field.id)) {
                    collapsed.push(field.id);
                }

                continue;
            }

            if (currentSubheaderFieldId && !group[currentSubheaderFieldId]) {
                // If this is the first field in a subheader group, define the group
                group[currentSubheaderFieldId] = [field.id];
                continue;
            }

            if (currentSubheaderFieldId) {
                group[currentSubheaderFieldId]?.push(field.id);
            }
        }

        this.subheaderFieldGroups.next(group);
        this.collapsedSubheaderIds.next(collapsed);
    }

    get fullscreen(): BehaviorSubject<boolean> {
        return this.sideNav.fullscreen;
    }

    toggleFullscreen() {
        this.sideNav.toggleFullscreen();
    }

    private workspacePermissions(workspaceId: string) {
        if (!workspaceId) {
            return;
        }

        const workspacePermissions = this.v3Permissions.value[workspaceId];
        return workspacePermissions;
    }

    private workflowPermissions(workspaceId: string, workflowId: string): WorkflowPermissions | undefined {
        if (!workspaceId || !workflowId) {
            return;
        }

        const workspacePermissions = this.workspacePermissions(workspaceId);
        const workflowPermissions = workspacePermissions?.workflow?.[workflowId];

        return workflowPermissions;
    }

    private phasePermissions(workspaceId: string, workflowId: string, phaseId: string): PhasePermission | undefined {
        const phasePermissions = this.workflowPermissions(workspaceId, workflowId)?.phases?.[phaseId];
        return phasePermissions;
    }

    private copy(activityIds: string[], options?: ActivityCopyOptions): Promise<Activity[]> {
        return this.rpc.requestAsync('v2.activities.copy', [activityIds, options]) as Promise<Activity[]>;
    }

    private listenToWorkflowChanges(networkId: string, workflowId: string, phaseId?: string) {
        this.workflowUpdatedSubscription = this.core.processUpdated
            .pipe(takeUntil(this.onDestroy), debounceTime(500), pluck(networkId, workflowId))
            .subscribe({
                next: () => this.setWorkflowAndTemplate(workflowId, phaseId),
            });
    }

    /** Sets workflow, phase and template */
    private async setWorkflowAndTemplate(workflowId: string, phaseId?: string) {
        try {
            this.workflow.next(this.core.processById(workflowId));

            if (!this.workflow.value) {
                throw new Error('Failed to find a workflow');
            }

            // Defaults to the first phase of an workflow
            this.phaseId = phaseId ? phaseId : this.workflow.value.phasesOrder?.[0];
            if (!this.phaseId) {
                return void console.warn('Cannot fetch template without phaseId');
            }

            this.template.next(await this.getTemplate(this.workflow.value._id, this.phaseId));
            if (!this.template.value?.fields) {
                return;
            }

            this.setSubheaderFieldGroups(this.template.value.fields);
        } catch (error) {
            console.warn('Failed!', error);
        }
    }

    private getPermissions(uid: string, networkId: string): Promise<PermissionMap> {
        return this.rpc.requestAsync('v3.activity.permissions', [uid, networkId]) as Promise<PermissionMap>;
    }

    private async getActivity(activityId: string): Promise<Activity> {
        return this.rpc.requestAsync('activities.load', [activityId]) as Promise<Activity>;
    }

    /** TODO: Move this to components */
    private ensureLinkedFromGroupOrder(linkedFromMap: LinkedFromGroupMap): LinkedFromGroupMap {
        if (!Object.keys(linkedFromMap || {}).length) {
            return {};
        }

        const orderedLinkedFromMap: LinkedFromGroupMap = {};
        const workspaceId = this.workflow.value?.cid;
        const workspaceWorkflows = workspaceId ? this.core.processes.value?.[workspaceId] : [];

        if (!workspaceWorkflows) {
            console.error('Failed to find workspace workflows');
            return linkedFromMap;
        }

        for (const workflowId in workspaceWorkflows) {
            if (!workflowId) {
                continue;
            }

            const linkedFromWorkflow = linkedFromMap[workflowId];
            if (!linkedFromWorkflow) {
                continue;
            }

            orderedLinkedFromMap[workflowId] = linkedFromWorkflow;

            const workspaceWorkflow = workspaceWorkflows[workflowId];
            const linkedPhases = linkedFromWorkflow.phases;
            const orderedLinkedPhases: LinkedFromGroupPhaseMap = {};

            for (const phaseId of workspaceWorkflow.phasesOrder) {
                const linkedPhase = linkedPhases?.[phaseId];

                if (!linkedPhase || !Object.keys(linkedPhase || {}).length) {
                    continue;
                }

                orderedLinkedPhases[phaseId] = linkedPhase;
            }

            const orderedWorkflow = orderedLinkedFromMap[workflowId];
            if (!orderedWorkflow) {
                continue;
            }

            orderedWorkflow.phases = orderedLinkedPhases;
        }

        return orderedLinkedFromMap;
    }

    private async updateLinkedActivityPriority(phaseId: string, activityId: string, priority: number) {
        if ((!priority && priority !== 0) || !phaseId || !activityId) {
            return;
        }

        const linkedFromGroup = this.linkedFromGroup.value;
        for (const workflowId in linkedFromGroup) {
            if (!workflowId) {
                continue;
            }

            const phase = linkedFromGroup?.[workflowId]?.phases?.[phaseId];
            if (!phase) {
                continue;
            }

            if (phase.activities.findIndex(({ _id }) => _id === activityId) < 0) {
                break;
            }

            this.refreshLinkedActivities(workflowId, phaseId, [activityId], { searchString: this.linkedFromSearchString });
            break;
        }

        this.linkedFromGroup.next(linkedFromGroup);
    }

    getTemplateField(fieldId?: string): ActivityTemplateField | null {
        if (!fieldId) {
            return null;
        }

        const workflowField = this.workflow.value?.fields[fieldId];

        if (!workflowField) {
            return null;
        }

        return {
            id: fieldId,
            label: workflowField?.label,
            required: workflowField?.required,
            subtype: workflowField?.type,
            data: workflowField?.data,
            defaultTo: workflowField?.defaultTo,
        };
    }
}
